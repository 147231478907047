import React, { useEffect } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Link } from "react-router-dom";
import axios from "../../libs/axios";
import useAlert from "../../hooks/alerts";
import TableView from "../../components/Table/Table";

function Jurisdictions() {
  const [jurisdictions, setJurisdictions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    getJurisdictions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { dispatchAlert } = useAlert();

  const getJurisdictions = () => {
    axios({
      url: "/jurisdictions",
    })
      .then((response) => {
        if (response.data.data.error) {
          dispatchAlert({
            open: true,
            type: "danger",
            message: response.data.data.error,
            timeout: 4000,
          });
        }
        setJurisdictions(response.data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formatAddress = (jur) => {
    // {jur.street_address}{" "}
    // {jur.zip}
    // {jur.city},{jur.state}{" "}
    const { street_address, city, zip, state } = jur;
    let addString = "";
    if (street_address) {
      addString += street_address;
    }

    if (zip) {
      addString += " " + zip;
    }

    if (city) {
      addString += ", " + city;
    }

    if (state) {
      addString += ", " + state;
    }

    return addString;
  };

  const formatLocation = (jurisdiction) => {
    return `${
      jurisdiction.county_name?.length ? jurisdiction.county_name + ", " : ""
    }${jurisdiction.state?.length ? jurisdiction.state + ", " : ""}${
      jurisdiction.location_name ? "(" + jurisdiction.location_name + ")" : ""
    }`;
  };

  const columns = [
    {
      name: "#",
    },
    {
      name: "Location",
    },
    {
      name: "Address",
    },
  ];

  const rows = jurisdictions.map((jur, index) => {
    return {
      id: jur.id,
      endpoint: `/jurisdictions/${jur.id}`,
      api_endpoint: `/jurisdictions/${jur.id}`,
      fullRecord: jur,
      columns: [
        {
          display: jur.id,
          value: jur.id,
        },
        {
          display: formatLocation(jur),
          value: formatLocation(jur),
        },
        {
          display: formatAddress(jur),
          value: formatAddress(jur),
        },
      ],
    };
  });

  if (isLoading) {
    return <div className="loader mt-7" />;
  }
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Jurisdictions"
          subtitle="Jurisdictions management"
          className=""
        />
        <Link className="btn btn-primary btn-lg" to="/jurisdictions/add">
          New Jurisdiction
        </Link>
      </Row>
      <br />

      <Row>
        <Col>
          <TableView
            columns={columns}
            rows={rows}
            name="Jurisdictions"
            refreshTable={getJurisdictions}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Jurisdictions;
