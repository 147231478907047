import React, { useEffect } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Link } from "react-router-dom";
import axios from "../../libs/axios";
import useUser from "../../hooks/useUser";
import useAlert from "../../hooks/alerts";
import TableView from "../../components/Table/Table";

function Users() {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const UserContext = useUser();
  const { hasRole } = UserContext;
  const { dispatchAlert } = useAlert();

  //  get users
  const getUsers = () => {
    axios
      .get("/users")
      .then((res) => {
        if (res.data.data.error) {
          dispatchAlert({
            open: true,
            type: "danger",
            message: res.data.data.error,
            timeout: 4000,
          });
          return;
        }
        setUsers(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          open: true,
          type: "danger",
          message: err,
          timeout: 4000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "#",
    },
    {
      name: "Name",
    },
    {
      name: "Email",
    },
    {
      name: "Management Organization",
    }
  ];

  const rows = users.map((usr) => {
    return {
      id: usr.id,
      endpoint: `/users/${usr.id}`,
      api_endpoint: `/users/${usr.id}`,
      fullRecord: usr,
      columns: [
        {
          value: usr.id,
          display: usr.id,
        },
        {
          value: usr.name || "",
          display: usr.name,
        },
        {
          value: usr.email || "",
          display: usr.email,
        },
        {
          value: usr.organizations[0]?.name || "",
          display: usr.organizations[0]?.name || "",
        }
      ],
    };
  });

  if (loading) {
    return <div className="loader mt-7" />;
  }
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Users" subtitle="Users" className="" />
        {hasRole("superuser") && (
          <Link className="btn btn-primary btn-lg" to="/users/add">
            New User
          </Link>
        )}
      </Row>

      <br />

      <Row>
        <Col>
          <TableView
            name="Users"
            columns={columns}
            rows={rows}
            refreshTable={getUsers}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Users;
