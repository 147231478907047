import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "shards-react";
import PDFViewer from "./PDFViewer";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function ViewModal({ children, view_content, title, style, type = "pdf" }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const getContent = () => {
    switch (type) {
      case "pdf":
        return <PDFViewer file={view_content} shouldFetch={true} />;
      default:
        return view_content;
    }
  };

  return (
    <div
      style={
        {
          position: "relative",
          ...style,
        } || {}
      }
    >
      <span onClick={toggle}>{children}</span>
      <Modal size="lg" open={isOpen} toggle={toggle}>
        <ModalHeader>{title}</ModalHeader>
        <span
          className="exitButton"
          onClick={toggle}
          css={css`
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            position: absolute;
            top: 14px;
            right: 14px;
            opacity: 0.5;
            transition: all 0.2s ease-in-out;

            &:hover {
              opacity: 1;
            }
          `}
        >
          <span className="material-icons">close</span>
        </span>
        <ModalBody>{getContent()}</ModalBody>
        <ModalFooter>
          <Button theme="danger" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ViewModal;
