import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";
import useUser from "../../hooks/useUser";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ViewModal from "./ViewModal";
import useAlert from "../../hooks/alerts";
import axios from "../../libs/axios";

function ActionButtons({
  edit_endpoint,
  delete_message,
  handleSoftDelete,
  hard_deletes,
  hard_delete_message,
  handleHardDelete,
  edit_permission,
  handleRestore,
  restore_message,
  view_modal,
  view_content,
  view_title,
  download_button,
  download_endpoint,
  download_title,
}) {
  const { hasRole } = useUser();
  const [showEdit, setShowEdit] = useState(true);

  // if edit_permission exists, set show edit to true if hasrole(edit_permission)
  useEffect(() => {
    if (edit_permission) {
      setShowEdit(hasRole(edit_permission));
    }
  }, [edit_permission, hasRole]);

  const { dispatchAlert } = useAlert();

  const handleDownload = async () => {
    try {
      axios({
        method: "GET",
        url: download_endpoint,
        responseType: "blob",
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          const ext = res.headers["content-type"].split("/")[1];
          link.setAttribute("download", `${download_title}.${ext}`);
          document.body.appendChild(link);
          link.click();
          dispatchAlert({
            type: "success",
            message: "Document downloaded successfully",
            open: true,
            timeout: 4000,
          });
        })
        .catch((err) => {
          console.error(err);
          dispatchAlert({
            type: "danger",
            message: err,
            open: true,
            timeout: 4000,
          });
        });
    } catch (err) {
      console.error(err);
    }
  };

  const style = css`
    .action-icon {
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.8;
        transform: scale(1.01);
      }

      &:active {
        opacity: 0.6;
        transform: scale(0.99);
      }
    }
  `;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "8px",
      }}
      css={style}
    >
      {view_modal && view_content && view_title && (
        <ViewModal view_content={view_content} title={view_title} type="pdf">
          <span
            type="button"
            tabIndex={0}
            className="material-icons text-secondary list-view-actions action-icon"
            title={view_title}
          >
            visibility
          </span>
        </ViewModal>
      )}

      {download_button && (
        <span
          type="button"
          tabIndex={0}
          className="material-icons text-secondary list-view-actions action-icon"
          title="Download"
          onClick={handleDownload}
        >
          cloud_download
        </span>
      )}

      {showEdit && !hard_deletes && (
        <Link to={edit_endpoint}>
          <span
            title="Edit this item"
            className="material-icons list-view-actions action-icon"
          >
            mode_edit
          </span>
        </Link>
      )}

      {!hard_deletes && hasRole("attorney") && (
        <ConfirmModal
          confirmMessage={delete_message}
          onConfirm={handleSoftDelete}
        >
          <span
            type="button"
            tabIndex={0}
            className="material-icons text-danger list-view-actions action-icon"
            title="Delete this Item"
          >
            delete
          </span>
        </ConfirmModal>
      )}
      {hard_deletes && hasRole("attorney") && (
        <ConfirmModal
          confirmMessage={
            restore_message || "Are you sure you want to restore this item?"
          }
          onConfirm={handleRestore}
        >
          <span
            type="button"
            tabIndex={0}
            className="material-icons text-success list-view-actions action-icon"
            title="Restore Item"
          >
            restore
          </span>
        </ConfirmModal>
      )}
      {hard_deletes && hasRole("superuser") && (
        <ConfirmModal
          confirmMessage={
            hard_delete_message ||
            "Are you sure you want to delete this item forever?"
          }
          onConfirm={handleHardDelete}
        >
          <span
            type="button"
            tabIndex={0}
            className="material-icons list-view-actions action-icon"
            title="Delete Permanently"
          >
            delete_forever
          </span>
        </ConfirmModal>
      )}
    </div>
  );
}

export default ActionButtons;
