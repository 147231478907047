import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import axios from "../../libs/axios";
import useUser from "../../hooks/useUser";
import useAlert from "../../hooks/alerts";
import TableView from "../../components/Table/Table";

function Messages() {
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const organization_id =
    user.organizations?.length > 0 && user.organizations[0]?.id;
  const { dispatchAlert } = useAlert();

  const [messages, setMessages] = useState([]);
  const [messagesCreated, setMessagesCreated] = useState([]);

  const getMe = () => {
    setLoading(true);
    axios.get("/me").then((res) => {
      if (res.data.errors) {
        dispatchAlert({
          type: "danger",
          message: res.data.message,
          open: true,
          timeout: 4000,
        });
        return;
      }
      setMessages(res.data.data.messages);
      setMessagesCreated(res.data.data.messages_created);
      setLoading(false);
    });
  };

  useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shortenMessage = (message) => {
    const maxLength = 25;
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + "...";
    }
    return message;
  };

  const messsagesColumns = [
    {
      name: "Property Owner",
    },
    {
      name: "Resident 1",
    },
    {
      name: "Address",
    },
    {
      name: "Message Date",
    },
    {
      name: "Message",
      size: "lg",
    },
  ];

  const formatResident = (caseItem) => {
    if (!caseItem) return "";
    const { residents } = caseItem;
    if (!residents) return "";
    const resident1 = residents[0];
    if (!resident1) {
      return "";
    }
    let addString = "";

    if (resident1.first_name) {
      addString += resident1.first_name;
    }

    if (resident1.last_name) {
      addString += " " + resident1.last_name;
    }

    return addString;
  };

  const messagesRows = messages.map((message, index) => {
    return {
      id: message.id,
      endpoint: `/cases/${message.case.id}/${message.case.organization_id}?highlight=messages`,
      api_endpoint: `/organizations/${organization_id}/cases/${message.case_id}/messages/${message.id}`,
      fullRecord: message,
      disabled: !message.case,
      columns: [
        {
          display: message.case?.owner?.business_legal_name,
          value: message.case?.owner?.business_legal_name,
        },
        {
          display: formatResident(message.case),
          value: formatResident(message.case),
        },
        {
          display: message.case?.address,
          value: message.case?.address,
        },
        {
          display: message.sent_at,
          value: message.sent_at,
        },
        {
          display: shortenMessage(message.content),
          value: message.content,
        },
      ],
    };
  });

  const messagesCreatedRows = messagesCreated.map((message, index) => {
    return {
      id: message.id,
      endpoint: message.case
        ? `/cases/${message.case.id}/${message.case.organization_id}?highlight=messages`
        : "",
      api_endpoint: `/organizations/${organization_id}/cases/${message.case_id}/messages/${message.id}`,
      fullRecord: message,
      disabled: !message.case,
      columns: [
        {
          display: message.case?.owner?.business_legal_name,
          value: message.case?.owner?.business_legal_name,
        },
        {
          display: formatResident(message.case) || "Case Deleted",
          value: formatResident(message.case),
        },
        {
          display: message.case?.street_address,
          value: message.case?.street_address,
        },
        {
          display: message.sent_at,
          value: message.sent_at,
        },
        {
          display: message.content,
          value: message.content,
        },
      ],
    };
  });

  const sortRowsByDate = (rows) => {
    return rows.sort((a, b) => {
      // sort by date descending
      return new Date(b.fullRecord.sent_at) - new Date(a.fullRecord.sent_at);
    });
  };

  if (loading) {
    return <div className="loader mt-7" />;
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Messages"
          subtitle="Case messages"
          className=""
        />
      </Row>
      <br />

      <Row>
        <Col>
          <TableView
            name="Messages Received"
            columns={messsagesColumns}
            rows={sortRowsByDate(messagesRows)}
            refreshTable={getMe}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <TableView
            name="Messages Created"
            columns={messsagesColumns}
            rows={sortRowsByDate(messagesCreatedRows)}
            refreshTable={getMe}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Messages;
