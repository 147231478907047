/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import axios from "../../libs/axios";
import { useParams, useNavigate } from "react-router-dom";
import useAlert from "../../hooks/alerts";
import { getValidation } from "../../vars/fetching";
import validateForm from "../../vars/validateForm";
import states from "../../vars/stateTitleCase";
import DeleteButton from "../../components/common/DeleteButton";
import { jurisdictionsDisablePrefix } from "../../vars/apiRoutePrefixes";

const initialState = {
  county_name: "",
  location_name: "",
  street_address: "",
  city: "",
  state: "",
  zip: "",
};

const JurisdictionEdit = () => {
  let params = useParams();
  const [formState, setFormState] = useState(initialState);
  const [formLoading, setFormLoading] = useState(true);

  const { dispatchAlert } = useAlert();

  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getValidation("jurisdictions").then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  useEffect(() => {
    getJurisdiction();
    return () => {
      setFormState(initialState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.jurisdictionId]);

  // get jurisdiction
  const getJurisdiction = () => {
    axios
      .get(`/jurisdictions/${params.jurisdictionId}`)
      .then((res) => {
        if (res.data.errors?.length) {
          dispatchAlert({
            type: "danger",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        setFormState(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: "Error getting jurisdiction: " + err.message,
          open: true,
          timeout: 5000,
        });
        if (err.response.status === 404) {
          navigate("/jurisdictions");
        }
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  // handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  // handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormLoading(true);

    if (errors.length) {
      setFormLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    axios
      .put(`/jurisdictions/${params.jurisdictionId}`, formState)
      .then((res) => {
        if (res.data.errors?.length) {
          dispatchAlert({
            type: "danger",
            message: res.data.errors[0].message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        dispatchAlert({
          type: "success",
          message: "Jurisdiction updated successfully",
          open: true,
          timeout: 4000,
        });

        navigate("/jurisdictions");
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          open: true,
          message: "An error occurred in editing Juriisdiction",
          type: "danger",
          timeout: 4000,
        });
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Edit Jurisdiction"
          subtitle="Jurisdictions"
          className="text-sm-left"
        />
        <DeleteButton
          endpoint={jurisdictionsDisablePrefix(params.jurisdictionId)}
          redirect_to="/jurisdictions"
        />
      </Row>
      <Row>
        <Col>{formLoading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Jurisdiction Details</h6>
            </CardHeader>
            <Form className="p-4">
              <Row form>
                {/*county_name*/}
                <Col md="6" className="form-group">
                  <label htmlFor="feInputCountyName">County Name</label>
                  <FormInput
                    id="feInputCountyName"
                    name="county_name"
                    value={formState.county_name || ""}
                    onChange={handleInputChange}
                  />
                </Col>
                {/*location_name*/}
                <Col md="6" className="form-group">
                  <label htmlFor="feInputLocationName">Location Name</label>
                  <FormInput
                    id="feInputLocationName"
                    name="location_name"
                    value={formState.location_name || ""}
                    onChange={handleInputChange}
                  />
                </Col>
                {/*address*/}
                <Col md="12" className="form-group">
                  <label htmlFor="feInputAddress">Address</label>
                  <FormInput
                    id="feInputAddress"
                    name="street_address"
                    value={formState.street_address || ""}
                    onChange={handleInputChange}
                  />
                </Col>
                {/*city*/}
                <Col md="5" className="form-group">
                  <label htmlFor="feInputCity">City</label>
                  <FormInput
                    id="feInputCity"
                    name="city"
                    value={formState.city || ""}
                    onChange={handleInputChange}
                  />
                </Col>
                {/*state*/}
                <Col md="4" className="form-group">
                  <label htmlFor="feInputState">State</label>
                  <FormSelect
                    id="feInputState"
                    name="state"
                    value={formState.state || ""}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Choose State
                    </option>
                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.abbreviation} - {state.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/*zip*/}
                <Col md="3" className="form-group">
                  <label htmlFor="feInputZip">Zip</label>
                  <FormInput
                    id="feInputZip"
                    name="zip"
                    value={formState.zip || ""}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              {errors.length > 0 && (
                <Row form>
                  <p style={{ marginLeft: "6px" }}>
                    Please fill out the following fields:{" "}
                    <span style={{ fontWeight: "500" }}>
                      {errors.map((err) => err.name).join(", ")}
                    </span>
                  </p>
                </Row>
              )}
              <Row form>
                <Col md={1} className="form-group">
                  <Button
                    disabled={errors.length > 0}
                    onClick={handleFormSubmit}
                  >
                    Save
                  </Button>
                </Col>
                <Col md={2} className="form-group">
                  <Button
                    type="button"
                    className="btn-secondary"
                    style={{ marginLeft: "14px" }}
                    onClick={(e) => {
                      navigate("/jurisdictions");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={2} className="form-group">
                  <DeleteButton
                    endpoint={jurisdictionsDisablePrefix(params.jurisdictionId)}
                    redirect_to="/jurisdictions"
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default JurisdictionEdit;
