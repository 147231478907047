import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
} from "shards-react";
import useSearch from "../../../hooks/useSearch";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const NavbarSearch = () => {
  const searchContext = useSearch();

  return (
    <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
      <InputGroup seamless className="ml-3">
        <InputGroupAddon type="prepend">
          <InputGroupText>
            <i
              className="material-icons"
              css={css`
                font-size: 1rem !important;
                color: #495057;
              `}
            >
              search
            </i>
          </InputGroupText>
        </InputGroupAddon>
        <FormInput
          className="navbar-search"
          placeholder="Start typing to search..."
          onChange={(e) => {
            searchContext.setSearch({
              value: e.target.value,
              type: "",
            });
          }}
          css={css`
            padding-left: 2.25rem !important;
            color: #33383d;

            &:placeholder {
              color: #495057;
            }
          `}
        />
      </InputGroup>
    </Form>
  );
};

export default NavbarSearch;
