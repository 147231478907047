import React, {
  createContext,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";

const initialSearch = {
  value: "",
  type: "",
};

const SearchContext = createContext({
  search: initialSearch,
  setSearch: () => {},
});

export const SearchProvider = ({ children }) => {
  const [searchContext, setSearchContext] = useState(initialSearch);

  const value = useMemo(
    () => ({
      search: searchContext,
      setSearch: (search) => {
        setSearchContext(search);
      },
    }),
    [searchContext]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const SearchConsumer = SearchContext.Consumer;

export default SearchContext;
