import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Link } from "react-router-dom";
import axios from "../../libs/axios";
import useUser from "../../hooks/useUser";
import useAlert from "../../hooks/alerts";
import { getDocuments } from "../../vars/fetching";
import TableView from "../../components/Table/Table";

function Documents() {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const { user } = useUser();
  const organization_id =
    user.organizations?.length > 0 && user.organizations[0]?.id;
  const { dispatchAlert } = useAlert();

  const getCases = async () => {
    return await axios
      .get("/cases")
      .then((res) => {
        if (res.data.data.error) {
          dispatchAlert({
            open: true,
            type: "danger",
            message: res.data.data.error,
            timeout: 4000,
          });
          return;
        }
        setCases(res.data.data);
      })
      .catch((err) => {
        dispatchAlert({
          open: true,
          message: err,
          type: "danger",
          timeout: 4000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCaseDocuments = async () => {
    if (cases.length > 0) {
      let newDocs = [];
      for (let i = 0; i < cases.length; i++) {
        if (cases[i].deleted_at) {
          continue;
        }
        await getDocuments(organization_id, cases[i].id)
          // eslint-disable-next-line no-loop-func
          .then((res) => {
            newDocs = [...newDocs, ...res];
          })
          .catch((err) => {
            dispatchAlert({
              open: true,
              message: err,
              type: "danger",
              timeout: 4000,
            });
          });
        setDocuments(newDocs);
      }
    }
  };
  //  get cases - useEffect
  useEffect(() => {
    getCases();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCaseDocuments();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cases]);

  const getFormattedCase = (caseItem) => {
    let caseName = "";

    if (caseItem.residents[0]) {
      if (caseItem.residents[0].first_name) {
        caseName += caseItem.residents[0].first_name;
      }
      if (caseItem.residents[0].last_name) {
        caseName += ` ${caseItem.residents[0].last_name}`;
      }
    }

    const street = caseItem.street_address
      ? ` / ${caseItem.street_address}`
      : "";

    if (street) {
      caseName += street;
    }
    return caseName || `Case (${caseItem.id})`;
  };

  const columns = [
    {
      name: "#",
    },
    {
      name: "Name",
    },
    {
      name: "Title",
    },
    {
      name: "Case",
    },
  ];

  const [formattedDocuments, setFormattedDocuments] = useState([]);

  useEffect(() => {
    const newFormattedDocuments = documents.map((document) => {
      const caseItem = cases.find(
        (caseItem) => caseItem.id === document.case_id
      );
      return {
        ...document,
        case: getFormattedCase(caseItem),
      };
    });
    setFormattedDocuments(newFormattedDocuments);
  }, [documents, cases]);

  const smallerFileName = (fileName) => {
    // limit to 30 characters and replace the middle with ...
    if (fileName.length > 30) {
      return (
        fileName.substring(0, 15) +
        "..." +
        fileName.substring(fileName.length - 15, fileName.length)
      );
    }
    return fileName;
  };

  const rows = formattedDocuments.map((document, index) => {
    return {
      id: document.id,
      endpoint: `/cases/${document.case_id}/documents/${document.id}`,
      api_endpoint: `/organizations/${organization_id}/cases/${document.case_id}/documents/${document.id}`,
      fullRecord: document,
      columns: [
        {
          display: index + 1,
          value: index + 1,
        },
        {
          display: smallerFileName(document.file_name),
          value: document.file_name,
        },
        {
          display: document.title,
          value: document.title,
        },
        {
          display: document.case,
          value: document.case,
        },
      ],
    };
  });

  if (loading) {
    return <div className="loader mt-7" />;
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Documents"
          subtitle="Documents management"
          className=""
        />
        <Link className="btn btn-primary btn-lg" to="/documents/add">
          New Document
        </Link>
      </Row>
      <br />

      <Row>
        <Col>
          <TableView
            name="Active Documents"
            columns={columns}
            rows={rows}
            refreshTable={getCaseDocuments}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Documents;
