/* eslint-disable react-hooks/exhaustive-deps */
/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  Form,
  FormInput,
  ListGroup,
  ListGroupItem,
  FormSelect,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import axios from "../../libs/axios";
import useAlert from "../../hooks/alerts";
import { useParams, useNavigate } from "react-router-dom";
import { getOrganizations, getValidation } from "../../vars/fetching";
import Select from "react-select";
import { getRoles } from "../../vars/fetching";
import validateForm from "../../vars/validateForm";
import DeleteButton from "../../components/common/DeleteButton";
import { usersDisablePrefix } from "../../vars/apiRoutePrefixes";

const initialFormState = {
  name: "",
  email: "",
  role_id: null,
  organization_id: null,
  owner_ids: [],
  phone: "",
  active: "",
};

const UserEdit = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { dispatchAlert } = useAlert();
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState(null);
  const [userOwners, setUserOwners] = useState([]);
  const [owners, setOwners] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [allOwners, setAllOwners] = useState(0);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const { user_id } = useParams();

  const navigate = useNavigate();

  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);
  const [multiState, setMultiState] = useState([]);

  useEffect(() => {
    getValidation("users").then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  useEffect(() => {
    if (!roles?.length) {
      getRoles()
        .then((roles) => {
          setRoles(roles);
        })
        .catch((err) => {
          console.error(err);
          dispatchAlert({
            type: "danger",
            message: err.message,
            open: true,
            timeout: 5000,
          });
        });
    }
    if (!user) {
      axios
        .get(`/users/${user_id}`)
        .then((res) => {
          if (res.data.errors) {
            dispatchAlert({
              type: "danger",
              message: res.data.message,
              open: true,
              timeout: 5000,
            });
            return;
          }
          setUser(res.data.data);
          setFormState({
            name: res.data.data.name,
            email: res.data.data.email,
            password: res.data.data.password,
            role_id: res.data.data.role.id,
            organization_id: res.data.data.organizations[0].id,
            owner_ids: res.data.data.owners.map((owner) => owner.id),
            owners: res.data.data.owners,
            phone: res.data.data.phone,
            active: res.data.data.active === 1 ? true : false,
          });
          setUserOwners(
            res.data.data.owners.map((owner) => {
              return {
                value: owner.id,
                label: owner.business_legal_name,
              };
            })
          );
          setMultiState(
            res.data.data.owners.map((owner) => {
              return {
                value: owner.id,
                label: owner.business_legal_name,
              };
            })
          );
        })
        .catch((err) => {
          console.error(err);
          dispatchAlert({
            type: "danger",
            message: "Error fetching user" + err.message,
            open: true,
            timeout: 5000,
          });
          if (err.response.status === 404) {
            navigate("/users");
          }
        });
    }
    getOrganizations().then((organizations) => {
      setOrganizations([...organizations]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formState.organization_id && organizations.length) {
      const owners = organizations.find(
        (org) => Number(org.id) === Number(formState.organization_id)
      )?.owners;
      setOwners(owners);
      setUserOwners(
        owners.map((own) => {
          return {
            value: own.id,
            label: own.business_legal_name,
            selected: !!user.owners.find((userOwn) => {
              return Number(userOwn.id) === Number(own.id);
            }),
          };
        })
      );
    }
  }, [formState.organization_id, organizations]);

  useEffect(() => {
    if (allOwners) {
      setFormState({
        ...formState,
        owner_ids: owners.map((owner) => owner.id),
      });
      setMultiState([]);
    }
    if (!allOwners) {
      setFormState({
        ...formState,
        owner_ids: [],
      });
      setMultiState([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOwners]);

  // create form loading state
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormLoading(true);
    //  post to organizations

    if (errors.length) {
      setFormLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    axios
      .put(`/users/${user_id}`, formState)
      .then((res) => {
        if (res.data.errors) {
          dispatchAlert({
            type: "danger",
            message: res.data.data.message,
            open: true,
            timeout: 5000,
          });
          setFormLoading(false);
          return;
        }
        dispatchAlert({
          type: "success",
          message: "User updated successfully",
          open: true,
          timeout: 5000,
        });
        navigate("/users");
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: err.message,
          open: true,
          timeout: 5000,
        });
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setFormLoading(true);
    //  post to organizations
    axios
      .put(`/users/${user_id}`, {
        password: newPassword,
        password_confirmation: confirmPassword,
      })
      .then((res) => {
        if (res.data.errors) {
          dispatchAlert({
            type: "danger",
            message: res.data.data.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        dispatchAlert({
          type: "success",
          message: "Password updated successfully",
          open: true,
          timeout: 4000,
        });
        navigate("/users");
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: err,
          open: true,
          timeout: 4000,
        });
      });
  };

  const formatWord = (word) => {
    // Split by - and capitalize each word
    return word
      .split("-")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  useEffect(() => {
    setFormState({
      ...formState,
      owner_ids: [],
    });
    setMultiState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.organization_id]);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Edit User"
          subtitle="Users"
          className="text-sm-left"
        />
        <DeleteButton
          endpoint={usersDisablePrefix(user_id)}
          redirect_to="/users"
        />
      </Row>
      <Row>
        <Col>{formLoading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Profile Details</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/* First Name */}
                        <Col md="6" className="form-group">
                          <label htmlFor="feFirstName">Name</label>
                          <FormInput
                            id="feFirstName"
                            placeholder="First Name"
                            name="name"
                            value={formState.name}
                            onChange={handleChange}
                          />
                        </Col>
                        {/* Email */}
                        <Col md="6" className="form-group">
                          <label htmlFor="feEmail">Email</label>
                          <FormInput
                            type="email"
                            id="feEmail"
                            name="email"
                            placeholder="Email Address"
                            value={formState.email}
                            onChange={handleChange}
                            autoComplete="email"
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="role_id">Role</label>
                          <FormSelect
                            id="role_id"
                            name="role_id"
                            value={formState.role_id || ""}
                            onChange={handleChange}
                          >
                            <option value="">Select Role</option>
                            {roles?.length > 0 &&
                              roles?.map((role) => {
                                return (
                                  <option key={role.id} value={role.id}>
                                    {formatWord(role.name)}
                                  </option>
                                );
                              })}
                          </FormSelect>
                        </Col>
                        <Col md="6" className="form-group">
                          <label htmlFor="organization_id">
                            Management Organization
                          </label>
                          <FormSelect
                            id="organization_id"
                            name="organization_id"
                            value={formState.organization_id || ""}
                            onChange={handleChange}
                          >
                            <option value="">Select Organization</option>
                            {organizations.length > 0 &&
                              organizations?.map((organization) => {
                                return (
                                  <option
                                    key={organization.id}
                                    value={organization.id}
                                  >
                                    {formatWord(organization.name)}
                                  </option>
                                );
                              })}
                          </FormSelect>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6} className="form-group">
                          <label htmlFor="all_owners">Select All Owners?</label>
                          <FormSelect
                            id="all_owners"
                            name="all_owners"
                            value={allOwners}
                            onChange={(e) => {
                              setAllOwners(Number(e.target.value));
                            }}
                            disabled={!formState.organization_id}
                          >
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                          </FormSelect>
                        </Col>
                        <Col md="6" className="form-group">
                          <label htmlFor="owners">Owners</label>
                          {
                            <Select
                              id="owners"
                              name="owners"
                              isMulti
                              options={owners?.map((own) => {
                                return {
                                  value: own.id,
                                  label: own.business_legal_name,
                                };
                              })}
                              value={multiState}
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  owner_ids: e.map((owner) => owner.value),
                                });
                                setMultiState(e);
                              }}
                              styles={{
                                option: (provided) => ({
                                  ...provided,
                                  borderColor: "#e1e5eb",
                                }),
                              }}
                              isDisabled={
                                allOwners || !formState.organization_id
                              }
                            />
                          }
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6} className="form-group">
                          <label htmlFor="phone">Phone Number</label>
                          <FormInput
                            id="phone"
                            name="phone"
                            value={formState.phone || ""}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="6" className="form-group">
                          <label htmlFor="active">Active</label>
                          <FormSelect
                            id="active"
                            name="active"
                            value={formState.active || ""}
                            onChange={handleChange}
                          >
                            <option value={""}>Select an Option...</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </FormSelect>
                        </Col>
                      </Row>
                      {errors.length > 0 && (
                        <Row form>
                          <p style={{ marginLeft: "6px" }}>
                            Please fill out the following fields:{" "}
                            <span style={{ fontWeight: "500" }}>
                              {errors.map((err) => err.name).join(", ")}
                            </span>
                          </p>
                        </Row>
                      )}
                      <Row form>
                        <Col md={1}>
                          <Button
                            disabled={errors.length > 0}
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        </Col>
                        <Col md={2} className="form-group">
                          <Button
                            type="button"
                            className="btn-secondary"
                            style={{ marginLeft: "14px" }}
                            onClick={(e) => {
                              navigate("/owners");
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col md={1} className="form-group">
                          <DeleteButton
                            endpoint={usersDisablePrefix(user_id)}
                            redirect_to="/users"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="10">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Change Password</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="new-password">New Password</label>
                          <FormInput
                            id="new-password"
                            placeholder="New Password"
                            type="password"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <label htmlFor="confirm-password">
                            Confirm Password
                          </label>
                          <FormInput
                            type="password"
                            id="confirm-password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <div style={{ marginTop: "8px" }}>
                            {newPassword.length > 0 &&
                              (confirmPassword === newPassword ? (
                                <span className="text-success">
                                  Passwords Match
                                </span>
                              ) : (
                                <span className="text-danger">
                                  * Passwords do not match
                                </span>
                              ))}
                          </div>
                        </Col>
                      </Row>
                      <Button onClick={handleChangePassword}>
                        Change Password
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserEdit;
