import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import useUser from "../hooks/useUser";
import axios from "../libs/axios";
import useAlert from "../hooks/alerts";

const UserProfile = () => {
  const userContext = useUser();
  const { user, reload: reloadUser } = userContext;
  const [formState, setFormState] = useState({
    name: user.name,
    email: user.email,
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { dispatchAlert } = useAlert();

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  // create form loading state
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormLoading(true);
    //  post to organizations
    axios
      .put(`/auth/user/profile-information`, formState)
      .then((res) => {
        reloadUser();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setFormLoading(true);
    //  post to organizations
    axios
      .put(`/auth/user/password`, {
        password: newPassword,
        password_confirmation: confirmPassword,
        current_password: currentPassword,
      })
      .then((res) => {
        if (res.data.errors) {
          dispatchAlert({
            type: "danger",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        dispatchAlert({
          type: "success",
          message: "Password updated successfully",
          open: true,
          timeout: 4000,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: err,
          open: true,
          timeout: 4000,
        });
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="User Profile"
          subtitle="Overview"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col>{formLoading && <div className="loader" />}</Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Profile Details</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/* First Name */}
                        <Col md="6" className="form-group">
                          <label htmlFor="feFirstName">Name</label>
                          <FormInput
                            id="feFirstName"
                            placeholder="First Name"
                            name="name"
                            value={formState.name}
                            onChange={handleChange}
                          />
                        </Col>
                        {/* Email */}
                        <Col md="6" className="form-group">
                          <label htmlFor="feEmail">Email</label>
                          <FormInput
                            type="email"
                            id="feEmail"
                            name="email"
                            placeholder="Email Address"
                            value={formState.email}
                            onChange={handleChange}
                            autoComplete="email"
                          />
                        </Col>
                      </Row>
                      <Button onClick={handleSubmit} theme="accent">
                        Update Account
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Change Password</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="current-password">
                            Current Password
                          </label>
                          <FormInput
                            id="current-password"
                            placeholder="Current Password"
                            type="password"
                            name="currentPassword"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="new-password">New Password</label>
                          <FormInput
                            id="new-password"
                            placeholder="New Password"
                            type="password"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <label htmlFor="confirm-password">
                            Confirm Password
                          </label>
                          <FormInput
                            type="password"
                            id="confirm-password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <div style={{ marginTop: "8px" }}>
                            {newPassword.length > 0 &&
                              (confirmPassword === newPassword ? (
                                <span className="text-success">
                                  Passwords Match
                                </span>
                              ) : (
                                <span className="text-danger">
                                  * Passwords do not match
                                </span>
                              ))}
                          </div>
                        </Col>
                      </Row>
                      <Button onClick={handleChangePassword}>
                        Change Password
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
