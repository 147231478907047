const sidebarNavItems = () => {
  return [
    {
      title: "Dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      to: "/dashboard",
      roles: [
        "superuser",
        "attorney",
        "paralegal",
        "property-manager-supervisor",
        "property-manager",
      ],
      requiredRole: "property-manager",
    },
    {
      title: "Calendar",
      htmlBefore: '<i class="material-icons">calendar_month</i>',
      to: "/calendar",
      roles: [
        "superuser",
        "attorney",
        "paralegal",
        "property-manager-supervisor",
        "property-manager",
      ],
      requiredRole: "property-manager",
    },
    {
      title: "Cases",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/cases",
      roles: [
        "superuser",
        "attorney",
        "paralegal",
        "property-manager-supervisor",
        "property-manager",
      ],
      requiredRole: "property-manager",
    },
    // {
    //   title: "Documents",
    //   htmlBefore: '<i class="material-icons">article</i>',
    //   to: "/documents",
    //   roles: [
    //     "superuser",
    //     "attorney",
    //     "paralegal",
    //     "property-manager-supervisor",
    //     "property-manager",
    //   ],
    //   requiredRole: "property-manager",
    // },
    {
      title: "Management Organizations",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/organizations",
      roles: ["superuser"],
      requiredRole: "superuser",
    },
    {
      title: "Property Owners",
      htmlBefore: '<i class="material-icons">house</i>',
      to: "/owners",
      roles: ["superuser", "attorney", "paralegal", "property-manager-supervisor"],
      requiredRole: "property-manager-supervisor",
    },
    {
      title: "Jurisdictions",
      htmlBefore: '<i class="material-icons">business</i>',
      to: "/jurisdictions",
      roles: ["superuser", "attorney", "paralegal"],
      requiredRole: "paralegal",
    },
    {
      title: "Judges",
      htmlBefore: '<i class="material-icons">gavel</i>',
      to: "/judges",
      roles: ["superuser", "attorney", "paralegal"],
      requiredRole: "paralegal",
    },
    {
      title: "Users",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/users",
      roles: ["superuser", "attorney", "paralegal", "property-manager-supervisor"],
      requiredRole: "property-manager-supervisor",
    },
    {
      title: "Messages",
      htmlBefore: '<i class="material-icons">mail</i>',
      to: "/messages",
      roles: [
        "superuser",
        "attorney",
        "paralegal",
        "property-manager-supervisor",
        "property-manager",
      ],
      requiredRole: "property-manager",
    },
  ];
};

export default sidebarNavItems;
