import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import axios from "../../libs/axios";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function PDFViewer({ file, shouldFetch = false }) {
  const [loadedFile, setLoadedFile] = useState(file);

  useEffect(() => {
    if (shouldFetch) {
      axios
        .get(file, {
          responseType: "blob",
        })
        .then((response) => {
          const randomName =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
          const file = new File([response.data], `file-${randomName}file.pdf`, {
            type: "application/pdf",
          });
          setLoadedFile(file);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const handleResize = () => {};

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToNextPage() {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  }

  // Function to handle going to the previous page
  function goToPreviousPage() {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  }

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          height: calc(80vh - 120px);
          aspect-ratio: 1/1.41;
          overflow: hidden;
          overflow-y: scroll;
          scrollbar-width: thin;
          scrollbar-color: #ccc transparent;
          border: 1px solid #eaeaea;
          border-radius: 5px;
        `}
      >
        <Document file={loadedFile} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={400} height={564} />
        </Document>
      </div>
      <div
        css={css`
          text-align: right;
          position: absolute;
          bottom: 0px;
          right: 24px;
          left: 24px;

          button {
            background-color: transparent;
            border: none;
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;
            font-size: 20px;
            padding: 0;

            &:hover {
              opacity: 1;
            }

            &:disabled {
              &:hover {
                opacity: 0.5;
              }
            }
          }

          .page-numbers {
            opacity: 0.75;
          }
        `}
      >
        <div
          css={css`
            width: fit-content;
            margin-left: auto;
            display: flex;
            gap: 8px;
          `}
        >
          <button
            onClick={goToPreviousPage}
            disabled={pageNumber <= 1}
            className="material-icons"
          >
            arrow_back
          </button>
          <button
            onClick={goToNextPage}
            disabled={pageNumber >= numPages}
            className="material-icons"
          >
            arrow_forward
          </button>
        </div>
        <span className="page-numbers">
          Page {pageNumber} of {numPages}
        </span>
      </div>
    </div>
  );
}

export default PDFViewer;
