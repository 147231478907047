import React, { createContext, useState, useMemo } from "react";

const initialAlert = {
  title: "",
  message: "",
  type: "success",
  onClose: () => {},
  open: null,
  timeout: null,
};

const AlertContext = createContext({
  search: initialAlert,
  dispatchAlert: () => {},
});

export const AlertProvider = ({ children }) => {
  const [alertContext, setAlertContext] = useState(initialAlert);

  const value = useMemo(
    () => ({
      alert: alertContext,
      dispatchAlert: (alert) => {
        setAlertContext({ ...alertContext, ...alert });
      },
    }),
    [alertContext]
  );

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export const AlertConsumer = AlertContext.Consumer;

export default AlertContext;
