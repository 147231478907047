import React, { useEffect } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";

import routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/shards-dashboards.1.1.0.css";
import "./styles/global.scss";
import useBootstrap from "./hooks/useBootstrap";
import useUser from "./hooks/useUser";
import Login from "./components/login/Login";
import { SearchProvider } from "./contexts/SearchContext";
import useAlert from "./hooks/alerts";
import AlertBanner from "./components/AlertBanner/AlertBanner";
import PageNotFound from "./components/PageNotFound/PageNotFound";

const App = () => {
  useBootstrap();
  const userContext = useUser();
  const { hasRole, user } = userContext;

  // eslint-disable-next-line no-unused-vars
  const { alert, dispatchAlert } = useAlert();

  useEffect(() => {
    if (userContext.user.role && userContext.isLoggedIn) {
      const filteredRoutes = routes.filter((route) => {
        return route.requiredRole === "none" || hasRole(route.requiredRole);
      });
      userContext.setRoutes(filteredRoutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.user.role, userContext.isLoggedIn]);

  if (userContext.isLoading) {
    return <div className="loader" />;
  }

  if (!userContext.isLoggedIn && !userContext.isLoading) {
    return (
      <>
        <AlertBanner />
        <Login />
      </>
    );
  }

  return (
    <SearchProvider>
      <AlertBanner />
      <HashRouter basename={process.env.REACT_APP_BASENAME || ""}>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/404" element={<PageNotFound />} />
          {user.filteredRoutes?.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <route.layout>
                    <route.component />
                  </route.layout>
                }
              />
            );
          })}
        </Routes>
      </HashRouter>
    </SearchProvider>
  );
};

export default App;
