import React from "react";
import { formatDateYearMonthDay, formatResident } from "../../utils/formatting";
import { CardHeader } from "shards-react";
import styles from "./card.module.scss";

/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

function PendingDismissalRequests({ pendingDismissalRequests }) {
  const navigate = useNavigate();

  return (
    <div css={css``}>
      <CardHeader className="border-bottom">
        <h6 className="m-0">Pending Dismissal Requests</h6>
      </CardHeader>
      <div className="p-3">
        <table className="w-100">
          <thead>
            <tr>
              <th>Date Requested</th>
              <th>Case Number</th>
              <th>Management Organization</th>
              <th>Plaintiff</th>
              <th>Resident</th>
            </tr>
          </thead>
          <tbody>
            {pendingDismissalRequests.map((caseItem) => {
              return (
                <tr
                  key={caseItem.id}
                  className={styles["card-item"]}
                  onClick={() => {
                    navigate(
                      `/cases/${caseItem.id}/${caseItem.organization_id}`
                    );
                  }}
                >
                  <td>{formatDateYearMonthDay(caseItem.created_at)}</td>
                  <td>{caseItem.court_case_number}</td>
                  <td>{caseItem.organization.name}</td>
                  <td>{caseItem.owner.business_legal_name}</td>
                  <td>{formatResident(caseItem)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        {!pendingDismissalRequests.length && <span>No cases.</span>}
      </div>
    </div>
  );
}

export default PendingDismissalRequests;
