// * Cases
export const casesPostPrefix = (organization_id) => {
  return `/organizations/${organization_id}/cases`;
};

export const casesGetPrefix = (organization_id, case_id) => {
  return `/organizations/${organization_id}/cases/${case_id}`;
};

export const casesPutPrefix = (organization_id, case_id) => {
  return `/organizations/${organization_id}/cases/${case_id}`;
};

export const casesDisablePrefix = (organization_id, case_id) => {
  return `/organizations/${organization_id}/cases/${case_id}/disable`;
};

export const casesDeletePrefix = (organization_id, case_id) => {
  return `/organizations/${organization_id}/cases/${case_id}`;
};

export const casesRestorePrefix = (organization_id, case_id) => {
  return `/organizations/${organization_id}/cases/${case_id}/restore`;
};

export const casesValidatePrefix = (organization_id) => {
  return `/organizations/${organization_id}/cases/create`;
};

// * Documents
export const documentsPostPrefix = (organization_id, case_id) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents`;
};

export const documentsGetPrefix = (organization_id, case_id) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents`;
};

export const individualDocumentsGetPrefix = (
  organization_id,
  case_id,
  document_id
) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents/${document_id}`;
};

export const documentsPutPrefix = (organization_id, case_id, document) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents/${document}`;
};

export const documentsDisablePrefix = (organization_id, case_id, document) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents/${document}/disable`;
};

export const documentsDeletePrefix = (organization_id, case_id, document) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents/${document}`;
};

export const documentDownloadPrefix = (organization_id, case_id, document) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents/${document}/download`;
};

export const documentRestorePrefix = (organization_id, case_id, document) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents/${document}/restore`;
};

export const documentsValidatePrefix = (organization_id, case_id) => {
  return `/organizations/${organization_id}/cases/${case_id}/documents/create`
}

// * Judges
export const judgesPostPrefix = (jurisdiction_id) => {
  return `/jurisdictions/${jurisdiction_id}/judges`;
};

export const judgesGetPrefix = (jurisdiction_id, judge_id) => {
  return `/jurisdictions/${jurisdiction_id}/judges/${judge_id}`;
};

export const judgesPutPrefix = (jurisdiction_id, judge_id) => {
  return `/jurisdictions/${jurisdiction_id}/judges/${judge_id}`;
};

export const judgesDisablePrefix = (jurisdiction_id, judge_id) => {
  return `/jurisdictions/${jurisdiction_id}/judges/${judge_id}/disable`;
};

export const judgesDeletePrefix = (jurisdiction_id, judge_id) => {
  return `/jurisdictions/${jurisdiction_id}/judges/${judge_id}`;
};

export const judgesRestorePrefix = (jurisdiction_id, judge_id) => {
  return `/jurisdictions/${jurisdiction_id}/judges/${judge_id}/restore`;
};

// * Jurisdictions
export const jurisdictionsPostPrefix = () => {
  return `/jurisdictions`;
};

export const jurisdictionsGetPrefix = (jurisdiction_id) => {
  return `/jurisdictions/${jurisdiction_id}`;
};

export const jurisdictionsPutPrefix = (jurisdiction_id) => {
  return `/jurisdictions/${jurisdiction_id}`;
};

export const jurisdictionsDisablePrefix = (jurisdiction_id) => {
  return `/jurisdictions/${jurisdiction_id}/disable`;
};

export const jurisdictionsDeletePrefix = (jurisdiction_id) => {
  return `/jurisdictions/${jurisdiction_id}`;
};

export const jurisdictionsRestorePrefix = (jurisdiction_id) => {
  return `/jurisdictions/${jurisdiction_id}/restore`;
};

// * Organizations
export const organizationsPostPrefix = () => {
  return `/organizations`;
};

export const organizationsGetPrefix = (organization_id) => {
  return `/organizations/${organization_id}`;
};

export const organizationsPutPrefix = (organization_id) => {
  return `/organizations/${organization_id}`;
};

export const organizationsDisablePrefix = (organization_id) => {
  return `/organizations/${organization_id}/disable`;
};

export const organizationsDeletePrefix = (organization_id) => {
  return `/organizations/${organization_id}`;
};

export const organizationsRestorePrefix = (organization_id) => {
  return `/organizations/${organization_id}/restore`;
};

// * Owners
export const ownersPostPrefix = (organization_id) => {
  return `/organizations/${organization_id}/owners`;
};

export const ownersGetPrefix = (organization_id, owner_id) => {
  return `/organizations/${organization_id}/owners/${owner_id}`;
};

export const ownersPutPrefix = (organization_id, owner_id) => {
  return `/organizations/${organization_id}/owners/${owner_id}`;
};

export const ownersDisablePrefix = (organization_id, owner_id) => {
  return `/organizations/${organization_id}/owners/${owner_id}/disable`;
};

export const ownersDeletePrefix = (organization_id, owner_id) => {
  return `/organizations/${organization_id}/owners/${owner_id}`;
};

export const ownersRestorePrefix = (organization_id, owner_id) => {
  return `/organizations/${organization_id}/owners/${owner_id}/restore`;
};

// * Users
export const usersPostPrefix = () => {
  return `/users`;
};

export const usersGetPrefix = (user_id) => {
  return `/users/${user_id}`;
};

export const usersPutPrefix = (user_id) => {
  return `/users/${user_id}`;
};

export const usersDisablePrefix = (user_id) => {
  return `/users/${user_id}/disable`;
};

export const usersDeletePrefix = (user_id) => {
  return `/users/${user_id}`;
};

export const usersRestorePrefix = (user_id) => {
  return `/users/${user_id}/restore`;
};
