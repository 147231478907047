/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import axios from "../../libs/axios";
import { useParams, useNavigate } from "react-router-dom";
import useAlert from "../../hooks/alerts";
import { getValidation } from "../../vars/fetching";
import validateForm from "../../vars/validateForm";
import DeleteButton from "../../components/common/DeleteButton";
import { judgesDisablePrefix } from "../../vars/apiRoutePrefixes";

const initialFormState = {
  first_name: null,
  last_name: null,
  middle_initial: null,
  suffix: null,
  division: null,
  floor_number: null,
  phone: null,
  jurisdiction_id: null,
  wants_envelopes: null,
  num_copies_judgment_wanted: null,
};

const JudgeEdit = () => {
  let params = useParams();
  const [formState, setFormState] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);

  const { dispatchAlert } = useAlert();

  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getValidation("judges", {
      jurisdiction_id: formState.jurisdiction_id || 1000,
    }).then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  //  get jurisdictions
  useEffect(() => {
    axios
      .get("/jurisdictions")
      .then((res) => {
        if (res.data.errors?.length) {
          dispatchAlert({
            type: "danger",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        setJurisdictionOptions(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: err,
          open: true,
          timeout: 4000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getJudge();
    return () => {
      setFormState(initialFormState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.judgeId, params.jurisdictionId]);

  // get judge
  const getJudge = () => {
    axios
      .get(`/jurisdictions/${params.jurisdictionId}/judges/${params.judgeId}`)
      .then((res) => {
        if (res.data.errors?.length) {
          dispatchAlert({
            type: "danger",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        setFormState(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: "Error getting judge: " + err.message,
          open: true,
          timeout: 5000,
        });
        if (err.response.status === 404) {
          navigate("/judges");
        }
      });
  };

  //  handle change
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  //  handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (errors.length) {
      setLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    axios
      .put(
        `/jurisdictions/${formState.jurisdiction_id}/judges/${formState.id}`,
        formState
      )
      .then((res) => {
        if (res.data.errors?.length) {
          dispatchAlert({
            type: "danger",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
          setLoading(false);
          return;
        }
        dispatchAlert({
          type: "success",
          message: "Judge updated successfully",
          open: true,
          timeout: 4000,
        });
        navigate("/judges");
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: err,
          open: true,
          timeout: 4000,
        });
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Edit Judge"
          subtitle="Judges"
          className="text-sm-left"
        />
        <DeleteButton
          endpoint={judgesDisablePrefix(
            formState.jurisdiction_id,
            formState.id
          )}
          redirect_to="/judges"
        />
      </Row>
      <Row>
        <Col>{loading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Judge Details</h6>
            </CardHeader>
            <Form className="p-4">
              <Row form>
                {/*judge_first_name*/}
                <Col md="4" className="form-group">
                  <label htmlFor="judge_first_name">First Name</label>
                  <FormInput
                    id="judge_first_name"
                    name="first_name"
                    value={formState.first_name || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*judge_last_name*/}
                <Col md="4" className="form-group">
                  <label htmlFor="judge_last_name">Last Name</label>
                  <FormInput
                    id="judge_last_name"
                    name="last_name"
                    value={formState.last_name || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*judge_suffix*/}
                <Col md="4" className="form-group">
                  <label htmlFor="judge_suffix">Suffix</label>
                  <FormSelect
                    id="judge_suffix"
                    name="suffix"
                    value={formState.suffix || ""}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Suffix
                    </option>
                    <option value="Jr.">Jr.</option>
                    <option value="Sr.">Sr.</option>
                    <option value="I">I</option>
                    <option value="II">II</option>
                    <option value="III">III</option>
                    <option value="IV">IV</option>
                    <option value="V">V</option>
                  </FormSelect>
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="judge_middle_initial">Middle Initial</label>
                  <FormInput
                    id="judge_middle_initial"
                    name="middle_initial"
                    placeholder="Middle Initial"
                    type="text"
                    value={formState.middle_initial || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*jurisdiction_id*/}
                <Col md="6" className="form-group">
                  <label htmlFor="jurisdiction_id">Jurisdiction</label>
                  <FormSelect
                    id="jurisdiction_id"
                    name="jurisdiction_id"
                    value={formState.jurisdiction_id || ""}
                    onChange={handleChange}
                    disabled
                  >
                    <option value="" disabled>
                      Select Jurisdiction
                    </option>
                    {jurisdictionOptions.map((jurisdiction) => (
                      <option key={jurisdiction.id} value={jurisdiction.id}>
                        {jurisdiction.location_name} -{" "}
                        {jurisdiction.county_name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/*division*/}
                <Col md="6" className="form-group">
                  <label htmlFor="division">Division</label>
                  <FormInput
                    id="division"
                    name="division"
                    value={formState.division || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*floor_number*/}
                <Col md="6" className="form-group">
                  <label htmlFor="floor_number">Floor Number</label>
                  <FormInput
                    id="floor_number"
                    name="floor_number"
                    value={formState.floor_number || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*phone_number*/}
                <Col md="6" className="form-group">
                  <label htmlFor="phone_number">Phone Number</label>
                  <FormInput
                    id="phone_number"
                    name="phone"
                    value={formState.phone || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="wants_envelopes">Wants Envelopes</label>
                  <FormSelect
                    id="wants_envelopes"
                    name="wants_envelopes"
                    value={formState.wants_envelopes || ""}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </FormSelect>
                </Col>
              </Row>
              <Row form>
                {/*num_copies_judgment_wanted*/}
                <Col md="6" className="form-group">
                  <label htmlFor="num_copies_judgment_wanted">
                    Number of Copies of Judgment Wanted
                  </label>
                  <FormInput
                    type="number"
                    step="1"
                    min="0"
                    id="num_copies_judgment_wanted"
                    name="num_copies_judgment_wanted"
                    placeholder="Number of Copies of Judgment Wanted"
                    value={formState.num_copies_judgment_wanted || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              {errors.length > 0 && (
                <Row form>
                  <p style={{ marginLeft: "6px" }}>
                    Please fill out the following fields:{" "}
                    <span style={{ fontWeight: "500" }}>
                      {errors.map((err) => err.name).join(", ")}
                    </span>
                  </p>
                </Row>
              )}
              <Row form>
                <Col md={1} className="form-input">
                  <Button disabled={errors.length > 0} onClick={handleSubmit}>
                    Add
                  </Button>
                </Col>
                <Col md={2} className="form-group">
                  <Button
                    type="button"
                    className="btn-secondary"
                    style={{ marginLeft: "14px" }}
                    onClick={(e) => {
                      navigate("/judges");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={1} className="form-group">
                  <DeleteButton
                    endpoint={judgesDisablePrefix(
                      formState.jurisdiction_id,
                      formState.id
                    )}
                    redirect_to="/judges"
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default JudgeEdit;
