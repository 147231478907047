/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import axios from "../../libs/axios";
import { useNavigate } from "react-router-dom";
import { ownersPostPrefix } from "../../vars/apiRoutePrefixes";
import useAlert from "../../hooks/alerts";
import { getValidation } from "../../vars/fetching";
import validateForm from "../../vars/validateForm";
import states from "../../vars/stateTitleCase";

const initialFormState = {
  organization_id: null,
  business_legal_name: "",
  business_dba: "",
  street_address: "",
  city: "",
  state: "",
  zip: "",
  caption_language: "",
  petition_body_language: "",
};

const PropertyOwnerAdd = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [organizations, setOrganizations] = useState([]);
  const [formLoading, setFormLoading] = useState(true);

  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getValidation("owners", { organization_id: 3000 }).then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  const { dispatchAlert } = useAlert();

  useEffect(() => {
    axios
      .get("/organizations")
      .then((response) => {
        setOrganizations(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setFormLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormLoading(true);

    if (errors.length) {
      setFormLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    axios
      .post(ownersPostPrefix(formState.organization_id), formState)
      .then((res) => {
        if (res.data.errors?.length > 0) {
          dispatchAlert({
            type: "danger",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        dispatchAlert({
          type: "success",
          message: "Owner added successfully",
          open: true,
          timeout: 4000,
        });
        navigate(
          `/owners/${res.data.data.id}/${res.data.data.organization_id}`
        );
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: "Error adding owner",
          open: true,
          timeout: 4000,
        });
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add Property Owner"
          subtitle="Property Owner"
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col>{formLoading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Property Owner Details</h6>
            </CardHeader>
            <Form className="p-4">
              <Row form>
                {/*business_name */}
                <Col md="4" className="form-group">
                  <label htmlFor="feBusinessName">Business Legal Name</label>
                  <FormInput
                    id="feBusinessName"
                    name="business_legal_name"
                    value={formState.business_legal_name}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*business_dba_name*/}
                <Col md="4" className="form-group">
                  <label htmlFor="feBusinessDbaName">Business DBA Name</label>
                  <FormInput
                    id="feBusinessDbaName"
                    name="business_dba"
                    value={formState.business_dba}
                    onChange={handleChange}
                  />
                </Col>
                {/*property_manager*/}
                <Col md="4" className="form-group">
                  <label htmlFor="fePropertyManager">Management Organization</label>
                  <FormSelect
                    id="fePropertyManager"
                    name="organization_id"
                    value={formState.organization_id || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select Organization</option>
                    {organizations.map((organization) => (
                      <option key={organization.id} value={organization.id}>
                        {organization.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/*address*/}
                <Col md="12" className="form-group">
                  <label htmlFor="feAddress">Address</label>
                  <FormInput
                    id="feAddress"
                    required
                    name="street_address"
                    value={formState.street_address}
                    onChange={handleChange}
                  />
                </Col>
                {/*city*/}
                <Col md="5" className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormInput
                    id="feCity"
                    required
                    name="city"
                    value={formState.city}
                    onChange={handleChange}
                  />
                </Col>
                {/*state*/}
                <Col md="4" className="form-group">
                  <label htmlFor="feState">State</label>
                  <FormSelect
                    id="feState"
                    required
                    name="state"
                    value={formState.state || ""}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Choose State
                    </option>
                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.abbreviation} - {state.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/*zip*/}
                <Col md="3" className="form-group">
                  <label htmlFor="feZip">Zip</label>
                  <FormInput
                    id="feZip"
                    name="zip"
                    value={formState.zip}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*caption_language*/}
                <Col md="4" className="form-group">
                  <label htmlFor="feCaptionLanguage">Caption Language</label>
                  <FormInput
                    id="feCaptionLanguage"
                    required
                    name="caption_language"
                    value={formState.caption_language}
                    onChange={handleChange}
                  />
                </Col>
                {/*petition_body_language*/}
                <Col md="4">
                  <label htmlFor="fePetitionBodyLanguage">
                    Petition Body Language
                  </label>
                  <FormInput
                    id="fePetitionBodyLanguage"
                    required
                    name="petition_body_language"
                    value={formState.petition_body_language}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              {errors.length > 0 && (
                <Row form>
                  <p style={{ marginLeft: "6px" }}>
                    Please fill out the following fields:{" "}
                    <span style={{ fontWeight: "500" }}>
                      {errors.map((err) => err.name).join(", ")}
                    </span>
                  </p>
                </Row>
              )}
              <Row form>
                <Col md={1} className="form-group">
                  <Button disabled={errors.length > 0} onClick={handleSubmit}>
                    Save
                  </Button>
                </Col>
                <Col md={1} className="form-group">
                  <Button
                    type="button"
                    className="btn-danger"
                    style={{ marginLeft: "14px" }}
                    onClick={(e) => {
                      navigate("/owners");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PropertyOwnerAdd;
