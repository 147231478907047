export const formatDateYearMonthDay = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getYYMMDD = (date) => {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

export const formatResident = (caseItem) => {
  if (!caseItem) return "";
  const { residents } = caseItem;
  const resident1 = residents[0];
  if (!resident1) {
    return "N/A";
  }
  let addString = "";

  if (resident1.first_name) {
    addString += resident1.first_name;
  }

  if (resident1.last_name) {
    addString += " " + resident1.last_name;
  }

  return addString;
};

export const formatAddress = (caseItem) => {
  // {caseItem.street_address +
  //   `${
  //     caseItem.street_address2
  //       ? ", " + caseItem.street_address2
  //       : ""
  //   }`}
  const { street_address, street_address2 } = caseItem;
  let addString = "";
  if (street_address) {
    addString += street_address;
  }
  if (street_address2) {
    addString += ", " + street_address2;
  }
  return addString;
};
