/* eslint-disable react-hooks/exhaustive-deps */
/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";

import useUser from "../../hooks/useUser";

import {
  getJurisdictions,
  getJudges,
  getOwners,
  getValidation,
} from "../../vars/fetching";
import { casesPostPrefix } from "../../vars/apiRoutePrefixes";
import PageTitle from "../../components/common/PageTitle";
import axios from "../../libs/axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import validateForm from "../../vars/validateForm";
import useAlert from "../../hooks/alerts";
import ConfirmModal from "../../components/common/ConfirmModal";

const getCurrentDateTime = () => {
  // YYYY-MM-DDTHH:mm:ss
  // "2022-08-12T12:08"
  // Retain the number of digits per section, the zero padding is required
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }T${hour < 10 ? "0" + hour : hour}:${minute < 10 ? "0" + minute : minute}`;
};

const CaseAdd = () => {
  const { user } = useUser();

  const initialFormState = {
    residents: [
      {
        first_name: null,
        middle_name: null,
        last_name: null,
        suffix: null,
      },
    ],
    active: 1,
    accepted: "",
    on_hold: "",
    on_hold_reason: null,
    rejected: "",
    rejected_reason: null,
    notified_at: null,
    pm_internal_file_number: null,
    street_address: null,
    street_address2: null,
    city: null,
    state: null,
    zip: null,
    phone: null,
    jurisdiction_id: null,
    attorney: null,
    monthly_rent_amount: null,
    monthly_rent_due_date: null,
    balance: null,
    balance_date: null,
    is_a_squatter: false,
    is_a_holdover_tenant: false,
    is_for_a_lease_violation: false,
    is_an_emergency_eviction: false,
    prelitigation_notice_date: null,
    date_petition_filed: null,
    date_petition_accepted: null,
    court_case_number: null,
    hearings: [
      {
        date: null,
        trial: "",
      },
    ],
    judge_id: null,
    judgment_date: null,
    judgment_rent_balance: null,
    placement_late_balance: null,
    judgment_utility_balance: null,
    judgement_late_balance: null,
    judgment_additional_balance: null,
    judgment_additional_amount_nature: null,
    judgment_attorneys_fee: null,
    judgment_pps_cost: null,
    judgment_court_cost: null,
    judgment_total_due: null,
    case_status: [
      {
        description: "Case submitted to attorney",
        datetime: getCurrentDateTime(),
        staff: user.name,
      },
    ],
    date_served: null,
    time_served: null,
    method_of_service: null,
    person_served: null,
    owner_id: "",
    placement_date: null,
    utility_balance_at_placement: null,
    additional_balance_at_placement: null,
    reason_additional_balance: null,
    total_balance_at_placement: null,
    date_squatter_notice: null,
    date_holdover_notice: null,
    date_lease_violation: null,
    section_lease_violated: null,
    description_lease_violated: null,
    date_lease_violation_notice: null,
    incident_date: null,
    incident_time: null,
    incident_description: null,
    date_emergency_eviction_notice: null,
    dismissal_request: 0,
    dismissal_requester: null,
    dismissal_date: null,
    updated_ledger_request: null,
    updated_ledger_requester: null,
    writ_request: 0,
    writ_requester: null,
    writ_date: null,
    date_summons_sent_to_pps: null,
    date_return_filed_with_court: null,
    date_return_accepted_by_court: null,
    failure_to_pay_rent: false,
  };

  const [formState, setFormState] = useState({
    ...initialFormState,
  });
  const [formLoading, setFormLoading] = useState(false);
  const [jurisdictions, setJurisdictions] = useState([]);
  const [judges, setJudges] = useState([]);
  const [owners, setOwners] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [organizations, setOrganizations] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);
  const AlertContext = useAlert();
  const { dispatchAlert } = AlertContext;

  // TODO: If users can belong to multiple organizations/property managers, then this needs to change
  const organization_id =
    user.organizations?.length > 0 && user.organizations[0]?.id;

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    (async () => {
      formState.case_status[0].staff = user.name;

      if (jurisdictions.length < 1) {
        const newJurisdictions = await getJurisdictions();
        setJurisdictions(newJurisdictions);
      }
      if (owners.length < 1) {
        const newOwners = await getOwners(organization_id);
        setOwners(newOwners);
      }
      if (requiredFields.length < 1) {
        const validation = await getValidation("cases", {
          organization_id,
        });
        const newRequiredFields = Object.keys(validation.rules).filter((key) =>
          validation.rules[key].includes("required")
        );
        setRequiredFields(newRequiredFields);
        setErrors(validateForm(formState, newRequiredFields));
      }
    })();
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
    if (formState.jurisdiction_id) {
      getJudges(formState.jurisdiction_id).then((res) => {
        setJudges(res);
      });
    }
  }, [formState]);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (errors.length > 0) {
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    setFormLoading(true);
    axios
      .post(casesPostPrefix(organization_id), {
        ...formState,
        case_status:
          formState.case_status.length &&
          (formState.case_status[formState.case_status.length - 1].description
            ?.length ||
          formState.case_status[formState.case_status.length - 1].datetime
            ?.length ||
          formState.case_status[formState.case_status.length - 1].staff?.length
            ? formState.case_status
            : []),
        hearings:
          formState.hearings.length &&
          formState.hearings[formState.hearings.length - 1].date?.length
            ? formState.hearings
            : [],
        organization_id,
      })
      .then((res) => {
        if (res.data.errors?.length > 0) {
          dispatchAlert({
            type: "danger",
            message: res.data.data.message,
            open: true,
            timeout: 5000,
          });
          setFormLoading(false);
          return;
        }
        dispatchAlert({
          open: true,
          type: "success",
          message: "Case added successfully!",
          timeout: 4000,
        });
        setFormState({
          ...initialFormState,
          residents: [
            {
              first_name: null,
              middle_name: null,
              last_name: null,
            },
          ],
        });
        navigate({
          pathname: `/cases/${res.data.data.id}/${res.data.data.organization_id}`,
          search: createSearchParams({
            highlight: "documents",
          }).toString(),
        });
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: "Error adding case",
          open: true,
          timeout: 5000,
        });
      });
  };

  // TODO: Fix this so it actually formats things but preserves the value of the original input
  // eslint-disable-next-line no-unused-vars
  const formatDollarFields = (field) => {
    if (field) {
      // Return a float with 2 decimal places
      // 0 = 0.00
      // 1 = 0.01
      // 2 = 0.02
      const newField = parseFloat(field).toFixed(2);
      return newField;
    }
    return field;
  };
  useEffect(() => {
    const fields = [
      formState.judgment_rent_balance,
      formState.judgment_utility_balance,
      formState.judgment_additional_balance,
      formState.judgment_attorneys_fee,
      formState.judgment_pps_cost,
      formState.judgment_court_cost,
      formState.judgement_late_balance,
    ];
    const total = fields.reduce((acc, curr) => {
      return acc + (curr ? parseFloat(curr) : 0);
    }, 0);

    setFormState({
      ...formState,
      judgment_total_due: total,
    });
  }, [
    formState.judgment_rent_balance,
    formState.judgment_utility_balance,
    formState.judgment_additional_balance,
    formState.judgment_attorneys_fee,
    formState.judgment_pps_cost,
    formState.judgment_court_cost,
    formState.judgement_late_balance,
  ]);

  useEffect(() => {
    const fields = [
      formState.utility_balance_at_placement,
      formState.additional_balance_at_placement,
      formState.placement_late_balance,
      formState.balance,
    ];
    const total = fields.reduce((acc, curr) => {
      return acc + (curr ? parseFloat(curr) : 0);
    }, 0);

    setFormState({
      ...formState,
      total_balance_at_placement: total,
    });
  }, [
    formState.utility_balance_at_placement,
    formState.additional_balance_at_placement,
    formState.placement_late_balance,
    formState.balance,
  ]);

  const getCurrentDate = () => {
    // Get current date as YYYY-MM-DD
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  useEffect(() => {
    if (Number(formState.writ_request)) {
      setFormState({
        ...formState,
        writ_date: getCurrentDate(),
        writ_requester: user.name,
      });
    }
    if (!Number(formState.writ_request)) {
      setFormState({
        ...formState,
        writ_date: null,
        writ_requester: null,
      });
    }
  }, [formState.writ_request]);

  useEffect(() => {
    if (Number(formState.dismissal_request)) {
      setFormState({
        ...formState,
        dismissal_date: getCurrentDate(),
        dismissal_requester: user.name,
      });
    }
    if (!Number(formState.dismissal_request)) {
      setFormState({
        ...formState,
        dismissal_date: null,
        dismissal_requester: null,
      });
    }
  }, [formState.dismissal_request]);

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add Case"
          subtitle="Case management"
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col>{formLoading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            {/* <CardHeader className="border-bottom">
              <h6 className="m-0">Case Details</h6>
            </CardHeader> */}
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      {/* <section>
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "14px",
                              }}
                            >
                              Case Initiation
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="accepted">Accepted?</label>
                            <FormSelect
                              id="accepted"
                              type="checkbox"
                              name="accepted"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  accepted: e.target.value,
                                  rejected: e.target.value
                                    ? 0
                                    : formState.active,
                                  on_hold: e.target.value
                                    ? 0
                                    : formState.on_hold,
                                });
                              }}
                              value={formState.accepted}
                            >
                              <option value={""}>Select an Option</option>
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="on_hold">On Hold?</label>
                            <FormSelect
                              id="on_hold"
                              name="on_hold"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  on_hold: e.target.value,
                                  accepted: e.target.value
                                    ? 0
                                    : formState.accepted,
                                  rejected: e.target.value
                                    ? 0
                                    : formState.rejected,
                                });
                              }}
                              value={formState.on_hold}
                            >
                              <option value={""}>Select an Option</option>
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                          <Col md="8" className="form-group">
                            <label htmlFor="on_hold_reason">
                              Reason for Hold
                            </label>
                            <FormInput
                              id="on_hold_reason"
                              type="text"
                              name="on_hold_reason"
                              placeholder="Reason for Hold"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  on_hold_reason: e.target.value,
                                });
                              }}
                              value={formState.on_hold_reason || ""}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="rejected">Rejected?</label>
                            <FormSelect
                              id="rejected"
                              type="checkbox"
                              name="rejected"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  rejected: e.target.value,
                                  accepted: e.target.value
                                    ? 0
                                    : formState.accepted,
                                  on_hold: e.target.value
                                    ? 0
                                    : formState.on_hold,
                                });
                              }}
                              value={formState.rejected}
                            >
                              <option value={""}>Select an Option</option>
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                          <Col md="8" className="form-group">
                            <label htmlFor="rejected_reason">
                              Reason for Rejection
                            </label>
                            <FormInput
                              id="rejected_reason"
                              type="text"
                              name="rejected_reason"
                              placeholder="Reason for Rejection"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  rejected_reason: e.target.value,
                                });
                              }}
                              value={formState.rejected_reason || ""}
                            />
                          </Col>
                        </Row>
                      </section> */}
                      <section>
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "24px",
                              }}
                            >
                              Case Info
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="pm_internal_file_number">
                              Internal File Number
                            </label>
                            <FormInput
                              type="text"
                              id="pm_internal_file_number"
                              name="pm_internal_file_number"
                              placeholder="Internal File Number"
                              value={formState.pm_internal_file_number || ""}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="owner_id">Owner</label>
                            <FormSelect
                              id="owner_id"
                              type="select"
                              name="owner_id"
                              onChange={handleChange}
                              value={formState.owner_id || ""}
                            >
                              <option value={""}>Select an Option</option>
                              {owners?.length &&
                                owners.map((owner) => {
                                  return (
                                    <option key={owner.id} value={owner.id}>
                                      {owner.business_legal_name}
                                    </option>
                                  );
                                })}
                            </FormSelect>
                          </Col>
                        </Row>
                      </section>
                      <section>
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "24px",
                              }}
                            >
                              Residents
                            </label>
                          </Col>
                        </Row>
                        {formState.residents?.map((res, idx) => {
                          return (
                            // * Basic Info
                            <Row form key={"blaj" + idx}>
                              {/*Resident 1 Name*/}
                              <Col md="3" className="form-group">
                                <label
                                  htmlFor={`resident-${idx + 1}-first-name`}
                                >
                                  Resident {idx + 1} First Name
                                </label>
                                <FormInput
                                  id={`resident-${idx + 1}-first-name`}
                                  type="text"
                                  placeholder={`Resident ${idx + 1} first name`}
                                  name={`resident-${idx + 1}-first-name`}
                                  onChange={(e) => {
                                    res.first_name = e.target.value;
                                    setFormState({
                                      ...formState,
                                    });
                                  }}
                                  value={res.first_name || ""}
                                />
                              </Col>
                              <Col md="3" className="form-group">
                                <label htmlFor={`resident-${idx + 1}-middle`}>
                                  Resident {idx + 1} Middle Name
                                </label>
                                <FormInput
                                  id={`resident-${idx + 1}-middle`}
                                  type="text"
                                  placeholder={`Resident ${idx + 1} middle`}
                                  name={`resident-${idx + 1}-middle`}
                                  onChange={(e) => {
                                    res.middle_name = e.target.value;
                                    setFormState({
                                      ...formState,
                                    });
                                  }}
                                  value={res.middle_name || ""}
                                />
                              </Col>
                              <Col md="3" className="form-group">
                                <label
                                  htmlFor={`resident-${idx + 1}-last-name`}
                                >
                                  Resident {idx + 1} Last Name
                                </label>
                                <FormInput
                                  id={`resident-${idx + 1}-last-name`}
                                  type="text"
                                  placeholder={`Resident ${idx + 1} last name`}
                                  name={`resident-${idx + 1}-last-name`}
                                  onChange={(e) => {
                                    res.last_name = e.target.value;
                                    setFormState({ ...formState });
                                  }}
                                  value={res.last_name || ""}
                                />
                              </Col>
                              <Col md="3" className="form-group">
                                <label htmlFor={`resident-${idx + 1}-suffix`}>
                                  Resident 1 Suffix
                                </label>
                                <FormInput
                                  id={`resident-${idx + 1}-suffix`}
                                  type="text"
                                  placeholder={`Resident ${idx + 1} last name`}
                                  name={`resident-${idx + 1}-suffix`}
                                  onChange={(e) => {
                                    res.suffix = e.target.value;
                                    setFormState({ ...formState });
                                  }}
                                  value={res.suffix || ""}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {formState.residents?.length > 1 && (
                            <button
                              style={{
                                border: "none",
                                boxShadow: ".2px .2px 5px 0 rgba(0,0,0,0.25)",
                                borderRadius: "5px",
                                backgroundColor: "#ff0000",
                                color: "white",
                                marginRight: "8px",
                              }}
                              type="button"
                              title="Remove Resident"
                              onClick={() => {
                                setFormState({
                                  ...formState,
                                  residents: formState.residents.slice(
                                    0,
                                    formState.residents.length - 1
                                  ),
                                });
                              }}
                            >
                              <i className="material-icons">delete</i>
                            </button>
                          )}
                          <button
                            style={{
                              border: "none",
                              boxShadow: ".2px .2px 5px 0 rgba(0,0,0,0.25)",
                              borderRadius: "5px",
                              backgroundColor: "#007bff",
                              color: "white",
                            }}
                            type="button"
                            title="Add Resident"
                            onClick={() => {
                              setFormState({
                                ...formState,
                                residents: [
                                  ...formState.residents,
                                  {
                                    first_name: "",
                                    last_name: "",
                                    suffix: null,
                                  },
                                ],
                              });
                            }}
                          >
                            <i className="material-icons">add</i>
                          </button>
                        </div>
                      </section>
                      <section>
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "24px",
                              }}
                            >
                              Location
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="street_address">Address</label>
                            <FormInput
                              id="street_address"
                              placeholder="1234 Main St"
                              type="text"
                              name="street_address"
                              onChange={handleChange}
                              value={formState.street_address || ""}
                            />
                          </Col>

                          <Col md="6" className="form-group">
                            <label htmlFor="address_2">Address 2</label>
                            <FormInput
                              id="address_2"
                              placeholder="Apartment, Studio or Floor"
                              type="text"
                              name="street_address2"
                              onChange={handleChange}
                              value={formState.street_address2 || ""}
                            />
                          </Col>
                        </Row>

                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="city">City</label>
                            <FormInput
                              id="city"
                              type="text"
                              placeholder="City"
                              name="city"
                              onChange={handleChange}
                              value={formState.city || ""}
                            />
                          </Col>
                          <Col md="4" className="form-group">
                            <label htmlFor="state">State</label>
                            <FormSelect
                              id="state"
                              name="state"
                              onChange={handleChange}
                              value={formState.state || ""}
                            >
                              <option value={null}>Select an Option</option>
                              <option value="KS">KS - Kansas</option>
                              <option value="MO">MO - Missouri</option>
                            </FormSelect>
                          </Col>
                          <Col md="4" className="form-group">
                            <label htmlFor="zip">Zip</label>
                            <FormInput
                              id="zip"
                              type="text"
                              placeholder="Zip"
                              name="zip"
                              onChange={handleChange}
                              value={formState.zip || ""}
                            />
                          </Col>
                        </Row>
                      </section>

                      <section>
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "24px",
                              }}
                            >
                              Placement
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="placement_date">
                              Placement Date
                            </label>
                            <FormInput
                              type="date"
                              id="placement_date"
                              name="placement_date"
                              placeholder="Placement Date"
                              value={getCurrentDate()}
                              readOnly
                            />
                          </Col>
                        </Row>
                      </section>
                      <section>
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "24px",
                              }}
                            >
                              Rental Information
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="rent_amount">
                              Monthly Rent Amount
                            </label>
                            <FormInput
                              id="rent_amount"
                              type="number"
                              placeholder="Monthly Rent Amount"
                              name="monthly_rent_amount"
                              min="0.00"
                              step="0.01"
                              onChange={handleChange}
                              value={formState.monthly_rent_amount || ""}
                            />
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="rent_due_date">
                              {" "}
                              Monthly Rent Due Date
                            </label>
                            <FormInput
                              id="rent_due_date"
                              type="date"
                              placeholder="Monthly Rent Due Date"
                              name="monthly_rent_due_date"
                              onChange={handleChange}
                              value={formState.monthly_rent_due_date || ""}
                            />
                          </Col>
                        </Row>
                      </section>
                      <section>
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "24px",
                              }}
                            >
                              Reason(s) for Eviction
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group">
                            <label htmlFor="failure_to_pay_rent">
                              Failure to Pay Rent?
                            </label>
                            <FormSelect
                              id="failure_to_pay_rent"
                              name="failure_to_pay_rent"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  failure_to_pay_rent: e.target.value,
                                });
                              }}
                              value={formState.failure_to_pay_rent}
                            >
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                        </Row>
                        {Number(formState.failure_to_pay_rent) === 1 && (
                          <>
                            <Row form>
                              <Col md="6" className="form-group">
                                <label htmlFor="prelitigation_notice_date">
                                  Date of Prelitigation Notice
                                </label>
                                <FormInput
                                  id="prelitigation_notice_date"
                                  name="prelitigation_notice_date"
                                  type="date"
                                  onChange={handleChange}
                                  value={
                                    formState.prelitigation_notice_date || ""
                                  }
                                />
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="balance_date">
                                  Balance Date
                                </label>
                                <FormInput
                                  id="balance_date"
                                  type="date"
                                  placeholder="Balance Date"
                                  name="balance_date"
                                  onChange={handleChange}
                                  value={formState.balance_date || ""}
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col md="4" className="form-group">
                                <label htmlFor="balance">Balance</label>
                                <FormInput
                                  id="balance"
                                  type="number"
                                  placeholder="Balance"
                                  min="0"
                                  step="0.01"
                                  name="balance"
                                  onChange={handleChange}
                                  value={formState.balance || ""}
                                />
                              </Col>
                              <Col md="4" className="form-group">
                                <label htmlFor="placement_late_balance">
                                  Placement Late Balance
                                </label>
                                <FormInput
                                  id="placement_late_balance"
                                  type="number"
                                  placeholder="Placement Late Balance"
                                  min="0"
                                  step="0.01"
                                  name="placement_late_balance"
                                  onChange={handleChange}
                                  value={formState.placement_late_balance || ""}
                                />
                              </Col>
                              <Col md="4" className="form-group">
                                <label htmlFor="utility_balance_at_placement">
                                  Utility Balance at Placement
                                </label>
                                <FormInput
                                  type="number"
                                  step="0.01"
                                  min="0"
                                  id="utility_balance_at_placement"
                                  name="utility_balance_at_placement"
                                  placeholder="Utility Balance at Placement"
                                  value={
                                    formState.utility_balance_at_placement || ""
                                  }
                                  onChange={handleChange}
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col md="6" className="form-group">
                                <label htmlFor="additional_balance_at_placement">
                                  Additional Balance at Placement
                                </label>
                                <FormInput
                                  type="number"
                                  step="0.01"
                                  min="0"
                                  id="additional_balance_at_placement"
                                  name="additional_balance_at_placement"
                                  placeholder="Additional Balance at Placement"
                                  value={
                                    formState.additional_balance_at_placement ||
                                    ""
                                  }
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="reason_additional_balance">
                                  Reason for Additional Balance
                                </label>
                                <FormInput
                                  type="text"
                                  id="reason_additional_balance"
                                  name="reason_additional_balance"
                                  placeholder="Reason for Additional Balance"
                                  value={
                                    formState.reason_additional_balance || ""
                                  }
                                  onChange={handleChange}
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col md="4" className="form-group">
                                <label htmlFor="total_balance_at_placement">
                                  Total Balance at Placement
                                </label>
                                <FormInput
                                  type="number"
                                  id="total_balance_at_placement"
                                  name="total_balance_at_placement"
                                  placeholder="Total Balance at Placement"
                                  value={
                                    formState.total_balance_at_placement || ""
                                  }
                                  onChange={handleChange}
                                  disabled={true}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row form>
                          <Col className="form-group">
                            <label htmlFor="is_an_emergency_eviction">
                              Emergency Eviction?
                            </label>
                            <FormSelect
                              id="is_an_emergency_eviction"
                              name="is_an_emergency_eviction"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  is_an_emergency_eviction: e.target.value,
                                });
                              }}
                              value={formState.is_an_emergency_eviction}
                            >
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                        </Row>
                        {Number(formState.is_an_emergency_eviction) === 1 && (
                          <>
                            <Row form>
                              <Col md="6" className="form-group">
                                <label htmlFor="incident_date">
                                  Incident Date
                                </label>
                                <FormInput
                                  type="date"
                                  id="incident_date"
                                  name="incident_date"
                                  placeholder="Incident Date"
                                  value={formState.incident_date || ""}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor="incident_time">
                                  Incident Time
                                </label>
                                <FormInput
                                  type="time"
                                  id="incident_time"
                                  name="incident_time"
                                  placeholder="Incident Time"
                                  value={formState.incident_time || ""}
                                  onChange={handleChange}
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col className="form-group">
                                <label htmlFor="incident_description">
                                  Incident Description
                                </label>
                                <FormInput
                                  type="text"
                                  id="incident_description"
                                  name="incident_description"
                                  placeholder="Description of Incident"
                                  value={formState.incident_description || ""}
                                  onChange={handleChange}
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col md="6" className="form-group">
                                <label htmlFor="date_emergency_eviction_notice">
                                  Date of Emergency Eviction Notice (if any)
                                </label>
                                <FormInput
                                  id="date_emergency_eviction_notice"
                                  type="date"
                                  placeholder="Date of Emergency Eviction Notice"
                                  name="date_emergency_eviction_notice"
                                  onChange={handleChange}
                                  value={
                                    formState.date_emergency_eviction_notice ||
                                    ""
                                  }
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row form>
                          <Col className="form-group">
                            <label htmlFor="is_for_a_lease_violation">
                              Lease Violation?
                            </label>
                            <FormSelect
                              id="is_for_a_lease_violation"
                              name="is_for_a_lease_violation"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  is_for_a_lease_violation: e.target.value,
                                });
                              }}
                              value={formState.is_for_a_lease_violation}
                            >
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                        </Row>
                        {Number(formState.is_for_a_lease_violation) === 1 && (
                          <>
                            <Row form>
                              <Col md="6" className="form-group">
                                <label htmlFor="date_lease_violation">
                                  Date of Lease Violation
                                </label>
                                <FormInput
                                  id="date_lease_violation"
                                  type="date"
                                  placeholder="Date of Lease Violation"
                                  name="date_lease_violation"
                                  onChange={handleChange}
                                  value={formState.date_lease_violation || ""}
                                />
                              </Col>

                              <Col md="6" className="form-group">
                                <label htmlFor="section_lease_violated">
                                  Section of Lease Violated
                                </label>
                                <FormInput
                                  id="section_lease_violated"
                                  type="text"
                                  placeholder="Section of Lease Violated"
                                  name="section_lease_violated"
                                  onChange={handleChange}
                                  value={formState.section_lease_violated || ""}
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col className="form-group">
                                <label htmlFor="description_lease_violated">
                                  Description of Lease Violation
                                </label>
                                <FormInput
                                  id="description_lease_violated"
                                  type="text"
                                  placeholder="Description of Lease Violation"
                                  name="description_lease_violated"
                                  onChange={handleChange}
                                  value={
                                    formState.description_lease_violated || ""
                                  }
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col md="6" className="form-group">
                                <label htmlFor="date_lease_violation_notice">
                                  Date of Lease Violation Notice
                                </label>
                                <FormInput
                                  id="date_lease_violation_notice"
                                  type="date"
                                  placeholder="Date of Lease Violation Notice"
                                  name="date_lease_violation_notice"
                                  onChange={handleChange}
                                  value={
                                    formState.date_lease_violation_notice || ""
                                  }
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row form>
                          <Col className="form-group">
                            <label htmlFor="is_a_squatter">Squatter?</label>
                            <FormSelect
                              id="is_a_squatter"
                              name="is_a_squatter"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  is_a_squatter: e.target.value,
                                });
                              }}
                              value={formState.is_a_squatter}
                            >
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                        </Row>
                        {Number(formState.is_a_squatter) === 1 && (
                          <>
                            <Row form>
                              <Col md="6" className="form-group">
                                <label htmlFor="date_squatter_notice">
                                  Date of Squatter Notice
                                </label>
                                <FormInput
                                  id="date_squatter_notice"
                                  type="date"
                                  placeholder="Date of Squatter Notice"
                                  name="date_squatter_notice"
                                  onChange={handleChange}
                                  value={formState.date_squatter_notice || ""}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row form>
                          <Col className="form-group">
                            <label htmlFor="is_a_holdover_tenant">
                              Is a Holdover Tenant
                            </label>
                            <FormSelect
                              id="is_a_holdover_tenant"
                              name="is_a_holdover_tenant"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  is_a_holdover_tenant: e.target.value,
                                });
                              }}
                              value={formState.is_a_holdover_tenant}
                            >
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                        </Row>
                        {Number(formState.is_a_holdover_tenant) === 1 && (
                          <>
                            <Row form>
                              <Col md="4" className="form-group">
                                <label htmlFor="date_holdover_notice">
                                  Date of Holdover Notice
                                </label>
                                <FormInput
                                  id="date_holdover_notice"
                                  type="date"
                                  placeholder="Date of Holdover Notice"
                                  name="date_holdover_notice"
                                  onChange={handleChange}
                                  value={formState.date_holdover_notice || ""}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </section>
                      <section
                        style={{
                          display: "none",
                        }}
                      >
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "14px",
                              }}
                            >
                              Court Information
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="jurisdiction_id">
                              Jurisdiction
                            </label>
                            <FormSelect
                              id="jurisdiction_id"
                              name="jurisdiction_id"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  jurisdiction_id: e.target.value,
                                  judge_id: null,
                                });
                              }}
                              value={formState.jurisdiction_id || ""}
                            >
                              <option value={null}>Select an Option</option>
                              {jurisdictions?.length > 0 &&
                                jurisdictions.map((jur, idx) => {
                                  return (
                                    <option
                                      key={jur.location_name + idx}
                                      value={jur.id}
                                    >
                                      {`${
                                        jur.county_name?.length > 1
                                          ? jur.county_name + ", "
                                          : ""
                                      }${
                                        jur.state?.length > 1
                                          ? jur.state + ", "
                                          : ""
                                      }(${jur.location_name})`}
                                    </option>
                                  );
                                })}
                            </FormSelect>
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="Attorney">Attorney</label>
                            <FormInput
                              id="Attorney"
                              type="text"
                              placeholder="Attorney"
                              name="attorney"
                              onChange={handleChange}
                              value={formState.attorney || ""}
                            />
                          </Col>
                        </Row>
                        {formState.jurisdiction_id && (
                          <Row>
                            <Col md="6" className="form-group">
                              <label htmlFor="phone">Judge</label>
                              <FormSelect
                                id="judge_id"
                                name="judge_id"
                                onChange={handleChange}
                                value={formState.judge_id || ""}
                              >
                                <option value={""}>Select an Option</option>
                                {judges?.length > 0 &&
                                  judges.map((judge, idx) => {
                                    return (
                                      <option
                                        key={judge.first_name + idx}
                                        value={judge.id}
                                      >
                                        {judge.first_name +
                                          " " +
                                          judge.last_name}
                                      </option>
                                    );
                                  })}
                              </FormSelect>
                            </Col>
                          </Row>
                        )}
                        <Row form>
                          {/*date_petition_filed*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="date_petition_filed">
                              Date Petition Filed
                            </label>
                            <FormInput
                              id="date_petition_filed"
                              type="date"
                              placeholder="Date Petition Filed"
                              name="date_petition_filed"
                              onChange={handleChange}
                              value={formState.date_petition_filed || ""}
                            />
                          </Col>
                          {/*date_petition_accepted*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="date_petition_accepted">
                              Date Petition Accepted
                            </label>
                            <FormInput
                              id="date_petition_accepted"
                              type="date"
                              placeholder="Date Petition Accepted"
                              name="date_petition_accepted"
                              onChange={handleChange}
                              value={formState.date_petition_accepted || ""}
                            />
                          </Col>
                          {/*court_case_number*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="court_case_number">
                              Court Case Number
                            </label>
                            <FormInput
                              id="court_case_number"
                              type="text"
                              placeholder="Court Case Number"
                              name="court_case_number"
                              onChange={handleChange}
                              value={formState.court_case_number || ""}
                            />
                          </Col>
                        </Row>

                        {formState.hearings?.map((hearing, idx) => {
                          return (
                            <Row form key={"blagrwh" + idx}>
                              <Col md="6" className="form-group">
                                <label htmlFor={`hearing_date_${idx}`}>
                                  Hearing Date {idx + 1}
                                </label>
                                <FormInput
                                  id={`hearing_date_${idx}`}
                                  type="date"
                                  placeholder={`Hearing Date ${idx}`}
                                  name="hearings"
                                  onChange={(e) => {
                                    let hearings = [...formState.hearings];
                                    hearings[idx].date = e.target.value;
                                    setFormState({
                                      ...formState,
                                      hearings: hearings,
                                    });
                                  }}
                                  value={formState.hearings[idx].date || ""}
                                />
                              </Col>
                              <Col md="6" className="form-group">
                                <label htmlFor={`hearing_trial_${idx}`}>
                                  Trial {idx + 1}
                                </label>
                                <FormSelect
                                  id={`hearing_trial_${idx}`}
                                  name={`hearing_trial_${idx}`}
                                  onChange={(e) => {
                                    let hearings = [...formState.hearings];
                                    hearings[idx].trial = e.target.value;
                                    setFormState({
                                      ...formState,
                                      hearings: hearings,
                                    });
                                  }}
                                  value={
                                    formState.hearings[idx].trial === null
                                      ? ""
                                      : formState.hearings[idx].trial
                                  }
                                >
                                  <option value={""}>Select an Option</option>
                                  <option value={0}>No</option>
                                  <option value={1}>Yes</option>
                                </FormSelect>
                              </Col>
                            </Row>
                          );
                        })}
                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {formState.hearings?.length > 1 && (
                            <button
                              style={{
                                border: "none",
                                boxShadow: ".2px .2px 5px 0 rgba(0,0,0,0.25)",
                                borderRadius: "5px",
                                backgroundColor: "#ff0000",
                                color: "white",
                                marginRight: "8px",
                              }}
                              type="button"
                              title="Remove Hearing"
                              onClick={() => {
                                setFormState({
                                  ...formState,
                                  hearings: formState.hearings?.slice(
                                    0,
                                    formState.hearings.length - 1
                                  ),
                                });
                              }}
                            >
                              <i className="material-icons">delete</i>
                            </button>
                          )}
                          <button
                            style={{
                              border: "none",
                              boxShadow: ".2px .2px 5px 0 rgba(0,0,0,0.25)",
                              borderRadius: "5px",
                              backgroundColor: "#007bff",
                              color: "white",
                            }}
                            type="button"
                            title="Add Hearing"
                            onClick={() => {
                              setFormState({
                                ...formState,
                                hearings: [...formState.hearings, ""],
                              });
                            }}
                          >
                            <i className="material-icons">add</i>
                          </button>
                        </div>

                        <Row form>
                          {/*judgment_date*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_date">Judgment Date</label>
                            <FormInput
                              id="judgment_date"
                              type="date"
                              placeholder="Judgment Date"
                              name="judgment_date"
                              onChange={handleChange}
                              value={formState.judgment_date || ""}
                            />
                          </Col>
                          {/*judgment_rent_balance*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_rent_balance">
                              Judgment Rent Balance
                            </label>
                            <FormInput
                              id="judgment_rent_balance"
                              type="number"
                              placeholder="Judgment Rent Balance"
                              min="0"
                              step="0.01"
                              name="judgment_rent_balance"
                              onChange={handleChange}
                              value={formState.judgment_rent_balance || ""}
                            />
                          </Col>
                          {/*judgment_utility_balance*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_utility_balance">
                              Judgment Utility Balance
                            </label>
                            <FormInput
                              id="judgment_utility_balance"
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Judgment Utility Balance"
                              name="judgment_utility_balance"
                              onChange={handleChange}
                              value={formState.judgment_utility_balance || ""}
                            />
                          </Col>
                          <Col md="4" className="form-group">
                            <label htmlFor="judgement_late_balance">
                              Judgment Late Balance
                            </label>
                            <FormInput
                              id="judgement_late_balance"
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Judgment Late Balance"
                              name="judgement_late_balance"
                              onChange={handleChange}
                              value={formState.judgement_late_balance || ""}
                            />
                          </Col>
                          {/*judgment_additional_balance*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_additional_balance">
                              Judgment Additional Balance
                            </label>
                            <FormInput
                              id="judgment_additional_balance"
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Judgment Additional Balance"
                              name="judgment_additional_balance"
                              onChange={handleChange}
                              value={
                                formState.judgment_additional_balance || ""
                              }
                            />
                          </Col>
                          {/*judgment_additional_amount_nature*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_additional_amount_nature">
                              Judgment Additional Amount Nature
                            </label>
                            <FormInput
                              id="judgment_additional_amount_nature"
                              type="text"
                              placeholder="Judgment Additional Amount Nature"
                              name="judgment_additional_amount_nature"
                              onChange={handleChange}
                              value={
                                formState.judgment_additional_amount_nature ||
                                ""
                              }
                            />
                          </Col>
                          {/*judgment_attorney_fee*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_attorney_fee">
                              Judgment Attorneys Fee
                            </label>
                            <FormInput
                              id="judgment_attorney_fee"
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Judgment Attorney Fee"
                              name="judgment_attorneys_fee"
                              onChange={handleChange}
                              value={formState.judgment_attorneys_fee || ""}
                            />
                          </Col>
                          {/*judgment_pps_cost*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_pps_cost">
                              Judgment PPS Cost
                            </label>
                            <FormInput
                              id="judgment_pps_cost"
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Judgment PPS Cost"
                              name="judgment_pps_cost"
                              onChange={handleChange}
                              value={formState.judgment_pps_cost || ""}
                            />
                          </Col>
                          {/*judgment_court_cost*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_court_cost">
                              Judgment Court Cost
                            </label>
                            <FormInput
                              id="judgment_court_cost"
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Judgment Court Cost"
                              name="judgment_court_cost"
                              onChange={handleChange}
                              value={formState.judgment_court_cost || ""}
                            />
                          </Col>
                          {/*judgment_total_due*/}
                          <Col md="4" className="form-group">
                            <label htmlFor="judgment_total_due">
                              Judgment Total Due
                            </label>
                            <FormInput
                              id="judgment_total_due"
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Judgment Total Due"
                              name="judgment_total_due"
                              // onChange={(e) => {}}
                              disabled={true}
                              value={formState.judgment_total_due || ""}
                            />
                          </Col>
                        </Row>
                      </section>
                      <section
                        style={{
                          display: "none",
                        }}
                      >
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "14px",
                              }}
                            >
                              Case Status
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="active">Active</label>
                            <FormSelect
                              id="active"
                              type="checkbox"
                              name="active"
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  active: e.target.value,
                                });
                              }}
                              value={formState.active || ""}
                            >
                              <option value={""}>Select an Option</option>
                              <option value={0}>No</option>
                              <option value={1}>Yes</option>
                            </FormSelect>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="updated_ledger_request">
                              Date Updated Ledger Requested
                            </label>
                            <FormInput
                              id="updated_ledger_request"
                              type="date"
                              name="updated_ledger_request"
                              placeholder="Date Updated Ledger Requested"
                              onChange={handleChange}
                              value={formState.updated_ledger_request || ""}
                            />
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="updated_ledger_requester">
                              User Requesting Updated Ledger
                            </label>
                            <FormInput
                              id="updated_ledger_requester"
                              type="text"
                              name="updated_ledger_requester"
                              placeholder="User Requesting Updated Ledger"
                              onChange={handleChange}
                              value={formState.updated_ledger_requester || ""}
                            />
                          </Col>
                        </Row>
                        {formState.case_status?.map((caseStatus, idx) => {
                          return (
                            <Row form key={"blgshraf" + idx}>
                              {/*case_status_1_description*/}
                              <Col md="4" className="form-group">
                                <label
                                  htmlFor={`case_status_${idx + 1}_description`}
                                >
                                  Case Status {idx + 1} Description
                                </label>
                                <FormInput
                                  id={`case_status_${idx + 1}_description`}
                                  type="text"
                                  placeholder={`Case Status ${
                                    idx + 1
                                  } Description`}
                                  name={`case_status_${idx + 1}_description`}
                                  onChange={(e) => {
                                    caseStatus.description = e.target.value;
                                    setFormState({
                                      ...formState,
                                    });
                                  }}
                                  value={caseStatus.description || ""}
                                />
                              </Col>
                              {/*case_status_1_date_time*/}
                              <Col md="4" className="form-group">
                                <label
                                  htmlFor={`case_status_${idx + 1}_date_time`}
                                >
                                  Case Status {idx + 1} Date Time
                                </label>
                                <FormInput
                                  id={`case_status_${idx + 1}_date_time`}
                                  type="datetime-local"
                                  placeholder={`Case Status ${
                                    idx + 1
                                  } Date Time`}
                                  readOnly={true}
                                  name={`case_status_${idx + 1}_date_time`}
                                  value={caseStatus.datetime || ""}
                                />
                              </Col>
                              {/*case_status_1_staff*/}
                              <Col md="4" className="form-group">
                                <label htmlFor={`case_status_${idx + 1}_staff`}>
                                  Case Status {idx + 1} Staff
                                </label>
                                <FormInput
                                  id={`case_status_${idx + 1}_staff`}
                                  type="text"
                                  placeholder={`Case Status ${idx + 1} Staff`}
                                  name={`case_status_${idx + 1}_staff`}
                                  value={caseStatus.staff || ""}
                                  readOnly={true}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {formState.case_status?.length > 1 && (
                            <button
                              style={{
                                border: "none",
                                boxShadow: ".2px .2px 5px 0 rgba(0,0,0,0.25)",
                                borderRadius: "5px",
                                backgroundColor: "#ff0000",
                                color: "white",
                                marginRight: "8px",
                              }}
                              type="button"
                              title="Remove Case Status"
                              onClick={() => {
                                setFormState({
                                  ...formState,
                                  case_status: formState.case_status.slice(
                                    0,
                                    formState.case_status.length - 1
                                  ),
                                });
                              }}
                            >
                              <i className="material-icons">delete</i>
                            </button>
                          )}
                          <button
                            style={{
                              border: "none",
                              boxShadow: ".2px .2px 5px 0 rgba(0,0,0,0.25)",
                              borderRadius: "5px",
                              backgroundColor: "#007bff",
                              color: "white",
                            }}
                            type="button"
                            title="Add Case Status"
                            onClick={() => {
                              setFormState({
                                ...formState,
                                case_status: [
                                  ...formState.case_status,
                                  {
                                    description: "",
                                    datetime: getCurrentDateTime(),
                                    staff: user.name,
                                  },
                                ],
                              });
                            }}
                          >
                            <i className="material-icons">add</i>
                          </button>
                        </div>
                      </section>
                      <section
                        style={{
                          display: "none",
                        }}
                      >
                        <Row>
                          <Col className="form-group">
                            <label
                              style={{
                                fontSize: "24px",
                                marginTop: "14px",
                              }}
                            >
                              Service Status
                            </label>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="date_summons_sent_to_pps">
                              Date Summons Sent To PPS
                            </label>
                            <FormInput
                              id="date_summons_sent_to_pps"
                              type="date"
                              name="date_summons_sent_to_pps"
                              placeholder="Date Summons Sent To PPS"
                              onChange={handleChange}
                              value={formState.date_summons_sent_to_pps || ""}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="3" className="form-group">
                            <label htmlFor="person_served">Person Served</label>
                            <FormInput
                              id="person_served"
                              type="text"
                              value={formState.person_served || ""}
                              onChange={handleChange}
                              placeholder="Person Served"
                              name="person_served"
                            />
                          </Col>
                          {/*date_served*/}
                          <Col md="3" className="form-group">
                            <label htmlFor="date_served">Date of Service</label>
                            <FormInput
                              id="date_served"
                              type="date"
                              placeholder="Date of Service"
                              name="date_served"
                              onChange={handleChange}
                              value={formState.date_served || ""}
                            />
                          </Col>
                          {/*time_served*/}
                          <Col md="3" className="form-group">
                            <label htmlFor="time_served">Time of Service</label>
                            <FormInput
                              id="time_served"
                              type="time"
                              placeholder="Time of Service"
                              name="time_served"
                              onChange={handleChange}
                              value={formState.time_served || ""}
                            />
                          </Col>
                          {/*method_of_service*/}
                          <Col md="3" className="form-group">
                            <label htmlFor="method_of_service">
                              Method of Service
                            </label>
                            <FormInput
                              id="method_of_service"
                              type="text"
                              placeholder="Method of Service"
                              name="method_of_service"
                              onChange={handleChange}
                              value={formState.method_of_service || ""}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="date_return_filed_with_court">
                              Date Return Filed With Court
                            </label>
                            <FormInput
                              id="date_return_filed_with_court"
                              type="date"
                              name="date_return_filed_with_court"
                              placeholder="Date Return Filed With Court"
                              onChange={handleChange}
                              value={
                                formState.date_return_filed_with_court || ""
                              }
                            />
                          </Col>
                          <Col md="4" className="form-group">
                            <label htmlFor="date_return_accepted_by_court">
                              Date Return Accepted By Court
                            </label>
                            <FormInput
                              id="date_return_accepted_by_court"
                              type="date"
                              name="date_return_accepted_by_court"
                              placeholder="Date Return Accepted By Court"
                              onChange={handleChange}
                              value={
                                formState.date_return_accepted_by_court || ""
                              }
                            />
                          </Col>
                          {/* <Col md="4" className="form-group">
                            <label htmlFor="notified_at">Notified At</label>
                            <FormInput
                              id="notified_at"
                              type="date"
                              name="notified_at"
                              placeholder="Notified At"
                              onChange={handleChange}
                              value={formState.notified_at || ""}
                            />
                          </Col> */}
                        </Row>
                      </section>

                      {errors.length > 0 && (
                        <Row form>
                          <p style={{ marginLeft: "6px" }}>
                            Please fill out the following fields:{" "}
                            <span style={{ fontWeight: "500" }}>
                              {errors.map((err) => err.name).join(", ")}
                            </span>
                          </p>
                        </Row>
                      )}
                      <ConfirmModal
                        onConfirm={handleSubmit}
                        confirmMessage="Are you sure you want to save this case?"
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <Button disabled={errors.length > 0} type="button">
                          Save
                        </Button>
                      </ConfirmModal>
                      <ConfirmModal
                        onConfirm={(e) => {
                          navigate("/cases");
                        }}
                        confirmMessage="Are you sure you want to cancel? Current information will be lost."
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <Button
                          type="button"
                          className="btn-danger"
                          style={{
                            marginLeft: "14px",
                          }}
                        >
                          Cancel
                        </Button>
                      </ConfirmModal>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CaseAdd;
