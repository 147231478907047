import React from "react";
import { Button } from "shards-react";
import axiosInstance from "../../libs/axios";
import useAlert from "../../hooks/alerts";
import ConfirmModal from "./ConfirmModal";
import { useNavigate } from "react-router-dom";

function DeleteButton({ endpoint, redirect_to, style }) {
  const { dispatchAlert } = useAlert();
  const navigate = useNavigate();

  const softDeleteRecord = () => {
    axiosInstance({
      url: endpoint,
      method: "POST",
    })
      .then((res) => {
        if (res.data.data.error) {
          dispatchAlert({
            open: true,
            type: "danger",
            message: res.data.data.error,
            timeout: 4000,
          });
          return;
        }
        dispatchAlert({
          type: "success",
          message: "Record deleted successfully",
          timeout: 4000,
          open: true,
        });
        navigate(redirect_to);
      })
      .catch((err) => {
        dispatchAlert({
          type: "danger",
          message: err.response.data.errors,
          timeout: 8000,
          open: true,
        });
      });
  };
  return (
    <div
      style={{
        ...style,
      }}
    >
      <ConfirmModal
        confirmMessage="Are you sure you want to delete this record?"
        onConfirm={() => {
          softDeleteRecord();
        }}
      >
        <Button type="button" className="btn-danger">
          Delete
        </Button>
      </ConfirmModal>
    </div>
  );
}

export default DeleteButton;
