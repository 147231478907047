// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import UserProfile from "./views/UserProfile";
import Cases from "./views/Cases/Cases";
import CaseAdd from "./views/Cases/Add";
import CaseEdit from "./views/Cases/Edit";
import PropertyOwners from "./views/PropertyOwners/PropertyOwners";
import PropertyOwnerAdd from "./views/PropertyOwners/Add";
import Jurisdictions from "./views/Jurisdictions/Jurisdictions";
import JurisdictionAdd from "./views/Jurisdictions/Add";
import Judges from "./views/Judges/Judges";
import JudgeAdd from "./views/Judges/Add";
import JudgeEdit from "./views/Judges/Edit";
import PropertyOwnerEdit from "./views/PropertyOwners/Edit";
import JurisdictionEdit from "./views/Jurisdictions/Edit";
import UserAdd from "./views/Users/Add";
import Users from "./views/Users/Users";
import UserEdit from "./views/Users/Edit";
import PropertyManagers from "./views/PropertyManagers/PropertyManagers";
import PropertyManagerAdd from "./views/PropertyManagers/Add";
import PropertyManagerEdit from "./views/PropertyManagers/Edit";
import DocumentAdd from "./views/Documents/Add";
import DocumentEdit from "./views/Documents/Edit";
import Documents from "./views/Documents/Documents";
import Messages from "./views/Messages/Messages";
import Dashboard from "./views/Dashboard/Dashboard";
import Calendar from "./views/Calendar/Calendar";
import { Navigate } from "react-router-dom";

const routes = [
  {
    path: "/",
    layout: DefaultLayout,
    component: () => <Navigate to="/dashboard" />,
    requiredRole: "none",
  },
  {
    path: "/calendar",
    layout: DefaultLayout,
    component: Calendar,
    requiredRole: "none",
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard,
    requiredRole: "none",
  },
  {
    path: "/profile",
    layout: DefaultLayout,
    component: UserProfile,
    requiredRole: "none",
  },
  {
    path: "/cases",
    layout: DefaultLayout,
    component: Cases,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
      "propery-manager",
    ],
    requiredRole: "property-manager",
  },
  {
    path: "/cases/add",
    layout: DefaultLayout,
    component: CaseAdd,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
      "propery-manager",
    ],
    requiredRole: "property-manager",
  },
  {
    path: "/cases/:case_id/:organization_id",
    layout: DefaultLayout,
    component: CaseEdit,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
      "propery-manager",
    ],
    requiredRole: "property-manager",
  },
  {
    path: "/documents",
    layout: DefaultLayout,
    component: Documents,
    requiredRole: "property-manager",
  },
  {
    path: "/documents/add",
    layout: DefaultLayout,
    component: DocumentAdd,
    requiredRole: "property-manager",
  },
  {
    path: "/documents/add/:case_id/:organization_id",
    layout: DefaultLayout,
    component: DocumentAdd,
    requiredRole: "property-manager",
  },
  {
    path: "cases/:case_id/documents/:document_id",
    layout: DefaultLayout,
    component: DocumentEdit,
    requiredRole: "property-manager",
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: Users,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
    ],
    requiredRole: "property-manager-supervisor",
  },
  {
    path: "/users/add",
    layout: DefaultLayout,
    component: UserAdd,
    roles: ["superuser", "attorney", "paralegal"],
    requiredRole: "paralegal",
  },
  {
    path: "/users/:user_id",
    layout: DefaultLayout,
    component: UserEdit,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
    ],
    requiredRole: "property-manager-supervisor",
  },
  {
    path: "/organizations",
    layout: DefaultLayout,
    component: PropertyManagers,
    roles: ["superuser", "attorney"],
    requiredRole: "attorney",
  },
  {
    path: "/organizations/add",
    layout: DefaultLayout,
    component: PropertyManagerAdd,
    roles: ["superuser", "attorney"],
    requiredRole: "attorney",
  },
  {
    path: "/organizations/:organizationId",
    layout: DefaultLayout,
    component: PropertyManagerEdit,
    roles: ["superuser", "attorney"],
    requiredRole: "attorney",
  },
  {
    path: "/owners",
    layout: DefaultLayout,
    component: PropertyOwners,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
    ],
    requiredRole: "property-manager-supervisor",
  },
  {
    path: "/owners/add",
    layout: DefaultLayout,
    component: PropertyOwnerAdd,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
    ],
    requiredRole: "property-manager-supervisor",
  },
  {
    path: "/owners/:ownerId/:organizationId",
    layout: DefaultLayout,
    component: PropertyOwnerEdit,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
    ],
    requiredRole: "property-manager-supervisor",
  },
  {
    path: "/jurisdictions",
    layout: DefaultLayout,
    component: Jurisdictions,
    roles: ["superuser", "attorney", "paralegal"],
    requiredRole: "paralegal",
  },
  {
    path: "/jurisdictions/add",
    layout: DefaultLayout,
    component: JurisdictionAdd,
    roles: ["superuser", "attorney", "paralegal"],
    requiredRole: "paralegal",
  },
  {
    path: "/jurisdictions/:jurisdictionId",
    layout: DefaultLayout,
    component: JurisdictionEdit,
    roles: ["superuser", "attorney", "paralegal"],
    requiredRole: "paralegal",
  },
  {
    path: "/judges",
    layout: DefaultLayout,
    component: Judges,
    roles: ["superuser", "attorney", "paralegal"],
    requiredRole: "paralegal",
  },
  {
    path: "/judges/add",
    layout: DefaultLayout,
    component: JudgeAdd,
    roles: ["superuser", "attorney", "paralegal"],
    requiredRole: "paralegal",
  },
  {
    path: "/judges/:judgeId/:jurisdictionId",
    layout: DefaultLayout,
    component: JudgeEdit,
    roles: ["superuser", "attorney", "paralegal"],
    requiredRole: "paralegal",
  },
  {
    path: "/messages",
    layout: DefaultLayout,
    component: Messages,
    roles: [
      "superuser",
      "attorney",
      "paralegal",
      "property-manager-supervisor",
      "propery-manager",
    ],
    requiredRole: "property-manager",
  },
];

export default routes;
