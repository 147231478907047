import React, { useState } from "react";
import { Card, CardBody, CardHeader } from "shards-react";
import useSearch from "../../hooks/useSearch";
import { useNavigate } from "react-router-dom";
import Styles from "./Table.module.scss";
import OptionModal from "../common/OptionModal";
import axiosInstance from "../../libs/axios";
import useAlert from "../../hooks/alerts";
import useUser from "../../hooks/useUser";

/**
 * Displays a table of data
 *
 * @param {Object} props
 * @param {string} props.name - the table's name
 * @param {Array} props.columns - the table's columns
 * @param {string} props.columns[].name - the column's name
 * @param {number} props.columns[].priority - the column's priority
 * @param {Array} props.rows - the table's rows
 * @param {string} props.rows[].id - the row's id
 * @param {string} props.rows[].endpoint - the row's endpoint
 * @param {string} props.rows[].api_endpoint - the row's api endpoint
 * @param {Object} props.fullRecord - the actual record that this row is based on
 * @param {Array} props.rows[].columns - the row's columns
 * @param {string} props.rows[].columns[].display - the data to display in the row
 * @param {string} props.rows[].columns[].value - the value of the data
 * @returns {JSX.Element}
 */
function TableView({
  name = "",
  columns = [],
  rows = [],
  refreshTable = () => {},
}) {
  const {
    search: { value },
  } = useSearch();

  const { dispatchAlert } = useAlert();

  const [selectedRow, setSelectedRow] = useState(null);

  const filteredRows = rows.filter((row) => {
    return row.columns.some((column) => {
      return (
        String(column.display)?.toLowerCase().includes(value.toLowerCase()) ||
        String(column.value)?.toLowerCase().includes(value.toLowerCase())
      );
    });
  });

  const navigate = useNavigate();

  const handleClick = (row) => {
    if (recordIsDeleted(row.fullRecord)) {
      return;
    }
    navigate(row.endpoint);
  };

  const handleDelete = (row) => {
    axiosInstance
      .delete(row.api_endpoint || row.endpoint)
      .then((res) => {
        dispatchAlert({
          type: "success",
          message: res.data.message,
        });
        refreshTable();
      })
      .catch((err) => {
        dispatchAlert({
          type: "error",
          message: err.response.data.message,
        });
      });
  };

  const handleRestore = (row) => {
    axiosInstance
      .post(`${row.api_endpoint || row.endpoint}/restore`)
      .then(() => {
        dispatchAlert({
          type: "success",
          message: "Record restored successfully",
        });
        navigate(row.endpoint);
      })
      .catch((err) => {
        dispatchAlert({
          type: "error",
          message: err.response.data.message,
        });
      });
  };

  const getRandomKey = () => (Math.random() + 1).toString(36).substring(5);

  const recordIsDeleted = (record) => {
    let isDeleted = false;
    if (record.hasOwnProperty("deleted_at")) {
      isDeleted = !!record.deleted_at;
    }

    return isDeleted;
  };

  const recordIsInactive = (record) => {
    let isActive = true;
    if (record.hasOwnProperty("active")) {
      isActive = !!record.active;
    }

    return !isActive;
  };

  const { hasRole } = useUser();

  return (
    <div>
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{name}</h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.name + getRandomKey()}
                    scope="col"
                    className={`border-0 ${
                      column.size ? Styles[`column-size-${column.size}`] : ""
                    }`}
                  >
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((row) =>
                recordIsDeleted(row.fullRecord) ? (
                  <OptionModal
                    key={row.id + getRandomKey()}
                    message="Would you like to restore or permanently delete this record?"
                    buttons={[
                      {
                        text: "Restore",
                        classes: "btn-success",
                        action: () => handleRestore(row),
                        invisible: !hasRole("attorney"),
                      },
                      {
                        text: "Hard Delete",
                        classes: "btn-danger",
                        action: () => handleDelete(row),
                        invisible: !hasRole("superuser"),
                      },
                    ]}
                    ChildrenContainer={({ children, onClick }) => (
                      <tr
                        onMouseEnter={() => setSelectedRow(row.id)}
                        onMouseLeave={() => setSelectedRow(null)}
                        className={`${Styles.row} ${
                          selectedRow === row.id ? Styles.selectedRow : ""
                        } ${
                          recordIsDeleted(row.fullRecord) ? Styles.deleted : ""
                        }`}
                        title={`Click to view ${row.id}`}
                        onClick={onClick}
                        key={row.id + getRandomKey()}
                      >
                        {children}
                      </tr>
                    )}
                    ModalContainer={({ children }) => (
                      <tr style={{ position: "absolute" }}>
                        <td>{children}</td>
                      </tr>
                    )}
                  >
                    {row.columns.map((column) => (
                      <td
                        key={column.display + getRandomKey()}
                        title={column.value}
                      >
                        {column.display}
                      </td>
                    ))}
                  </OptionModal>
                ) : (
                  <tr
                    key={row.id + getRandomKey()}
                    onMouseEnter={() => setSelectedRow(row.id)}
                    onMouseLeave={() => setSelectedRow(null)}
                    className={`${Styles.row} ${
                      selectedRow === row.id ? Styles.selectedRow : ""
                    } ${
                      recordIsInactive(row.fullRecord) ? Styles.inactive : ""
                    } ${row.disabled ? Styles.disabled_row : ""}`}
                    title={`Click to view ${row.id}`}
                  >
                    {row.columns.map((column, idx) => (
                      <td
                        key={column.display + getRandomKey()}
                        title={column.value}
                        onClick={() => {
                          if (row.disabled) return;
                          if (column.onClick) {
                            column.onClick(row.fullRecord);
                          } else {
                            handleClick(row);
                          }
                        }}
                        className={
                          columns[idx].size
                            ? Styles[`column-size-${columns[idx].size}`]
                            : ""
                        }
                      >
                        {column.display}
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
  );
}

export default TableView;
