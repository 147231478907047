import axios from "../libs/axios";
import {
  casesValidatePrefix,
  documentsValidatePrefix,
} from "./apiRoutePrefixes";

export const getCases = async (organization_id) => {
  try {
    const res = await axios.get(`/organizations/${organization_id}/cases`);
    return res.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getResidents = async (organization_id, case_id) => {
  try {
    const res = await axios.get(
      `/organizations/${organization_id}/cases/${case_id}/residents`
    );
    return res.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getJudges = async (jurisdiction_id) => {
  try {
    const res = await axios.get(`/jurisdictions/${jurisdiction_id}/judges`);
    return res.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getJurisdictions = async () => {
  try {
    const res = await axios.get("/jurisdictions");
    return res.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getOrganizations = async () => {
  try {
    const res = await axios.get("/organizations");
    return res.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getOwners = async () => {
  try {
    const res = await axios.get(`/owners`);
    return res.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getDocuments = async (organization_id, case_id) => {
  try {
    const response = await axios.get(
      `/organizations/${organization_id}/cases/${case_id}/documents`
    );
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getRoles = async () => {
  try {
    const response = await axios.get(`/roles`);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getValidation = async (module, options) => {
  try {
    let url;
    switch (module) {
      case "cases":
        url = casesValidatePrefix(options.organization_id);
        break;
      case "documents":
        url = documentsValidatePrefix(options.organization_id, options.case_id);
        break;
      case "organizations":
        url = `/organizations/create`;
        break;
      case "owners":
        url = `organizations/${options.organization_id}/owners/create`;
        break;
      case "jurisdictions":
        url = `/jurisdictions/create`;
        break;
      case "judges":
        url = `/jurisdictions/${options.jurisdiction_id}/judges/create`;
        break;
      case "users":
        url = `/users/create`;
        break;
      default:
        url = "";
    }
    const response = await axios.get(url);
    return response.data.data.validation;
  } catch (err) {
    console.error(err);
  }
};

export const getUser = async (user_id) => {
  try {
    const response = await axios.get(`/users/${user_id}`);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getOrganization = async (organization_id) => {
  try {
    const response = await axios.get(`/organizations/${organization_id}`);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};
