import React, { createContext, useState, useMemo, useEffect } from "react";
import axios from "../libs/axios";
import useAlert from "../hooks/alerts";

const initialUser = {
  id: null,
  active: null,
  created_at: "",
  deleted_at: "",
  email: "",
  email_verified_at: "",
  name: "",
  phone: null,
  role: {
    id: null,
    name: "",
  },
  filteredRoutes: null,
  organizations: [],
  owners: [],
  messages: [],
  messages_created: [],
};

const UserContext = createContext({
  user: initialUser,
  isLoggedIn: false,
  setUser: () => {},
  setIsLoggedIn: () => {},
  removeUser: () => {},
  reload: () => {},
  hasRole: () => {},
  setRoutes: () => {},
});

export function UserProvider(props) {
  const { dispatchAlert } = useAlert();
  const [userContext, setUserContext] = useState(initialUser);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios({
      url: "sanctum/csrf-cookie",
    })
      .then(() => {
        axios({ url: "/me" })
          .then((res) => {
            if (res.data.errors) {
              dispatchAlert({
                type: "danger",
                message: res.data.message,
                open: true,
                timeout: 4000,
              });
              return;
            }
            // dispatchAlert({
            //   type: "success",
            //   message: "User logged in successfully",
            //   open: true,
            //   timeout: 4000,
            // });
            setUserContext(res.data.data);
            setIsLoggedIn(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {
        dispatchAlert({
          type: "danger",
          message: "Error logging in",
          open: true,
          timeout: 4000,
        });
        setIsLoggedIn(false);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      user: userContext,
      isLoggedIn,
      isLoading,
      setUser: (user) => {
        const newUser = {
          id: user.id || 0,
          name: user.name || "",
          email: user.email || "",
          role_id: user.role_id || "",
          organizations: user.organizations || [],
          role: user.role || {
            id: user.role_id || null,
          },
          phone: user.phone || null,
          active: user.active || null,
          created_at: user.created_at || "",
          deleted_at: user.deleted_at || "",
          email_verified_at: user.email_verified_at || "",
          owners: user.owners || [],
          messages: user.messages || [],
          messages_created: user.messages_created || [],
        };

        setUserContext(newUser);
        setIsLoggedIn(true);
      },
      setIsLoggedIn: (bool) => {
        setIsLoggedIn(bool);
      },
      setRoutes: (routes) => {
        setUserContext({
          ...userContext,
          filteredRoutes: routes,
        });
      },
      removeUser: () => {
        axios({ url: "/auth/logout", method: "post" }).then(() => {
          dispatchAlert({
            type: "success",
            message: "User logged out successfully",
            open: true,
            timeout: 4000,
          });
          setUserContext(initialUser);
          setIsLoggedIn(false);
        });
      },
      reload: () => {
        setIsLoading(true);
        axios({ url: "/me" })
          .then((res) => {
            if (res.data.errors) {
              dispatchAlert({
                type: "danger",
                message: res.data.message,
                open: true,
                timeout: 4000,
              });
              return;
            }
            // dispatchAlert({
            //   type: "success",
            //   message: "User logged in successfully",
            //   open: true,
            //   timeout: 4000,
            // });
            setUserContext(res.data.data);
            setIsLoggedIn(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
      hasRole: (role) => {
        if (!userContext.role || !userContext.role.name) return false;
        const role_priority = [
          "superuser",
          "attorney",
          "paralegal",
          "property-manager-supervisor",
          "property-manager",
        ];

        let rolePriority = role_priority.indexOf(role);
        let validRoles = role_priority.slice(0, rolePriority + 1);
        return validRoles.includes(userContext.role.name);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userContext, isLoggedIn, isLoading]
  );

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}

export const UserConsumer = UserContext.Consumer;

export default UserContext;
