import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";
import axios from "../../libs/axios";
import PageTitle from "../../components/common/PageTitle";
import { useParams, useNavigate } from "react-router-dom";
import useAlert from "../../hooks/alerts";
import {
  individualDocumentsGetPrefix,
  documentsPutPrefix,
  documentDownloadPrefix,
  documentsDisablePrefix,
} from "../../vars/apiRoutePrefixes";
import useUser from "../../hooks/useUser";
import { getValidation } from "../../vars/fetching";
import validateForm from "../../vars/validateForm";
import DeleteButton from "../../components/common/DeleteButton";

const initialFormState = {
  title: "",
  court_category: "",
};

const DocumentEdit = () => {
  const [cases, setCases] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [formState, setFormState] = React.useState(initialFormState);
  const [requiredFields, setRequiredFields] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const { user: { organizations } } = useUser();
  const { case_id, document_id, organization_id = organizations?.length > 0 && organizations[0].id } = useParams();

  const { dispatchAlert } = useAlert();

  useEffect(() => {
    getValidation("documents", {
      organization_id: organization_id,
      case_id: case_id ? case_id : 10000,
    }).then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  const getCases = () => {
    axios
      .get("/cases")
      .then((res) => {
        setCases(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(individualDocumentsGetPrefix(organization_id, case_id, document_id))
      .then((res) => {
        if (res.data.errors?.length) {
          dispatchAlert({
            type: "danger",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
        }
        setFormState({
          title: res.data.data.title,
          case_id: res.data.data.case_id,
          court_category: res.data.data.court_category,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: "Error getting document: " + err.message,
          open: true,
          timeout: 4000,
        });
        if (err.response.status === 404) {
          navigate(`/cases/${case_id}/${organization_id}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cases.length === 0) {
      getCases();
    }
  }, [cases]);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (errors.length) {
      setLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    const formData = new FormData();
    Object.keys(formState).forEach((key) => {
      formData.append(key, formState[key]);
    });
    formData.append("_method", "PUT");

    axios
      .post(
        documentsPutPrefix(organization_id, formState.case_id, document_id),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data.errors?.length > 0) {
          dispatchAlert({
            type: "error",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        dispatchAlert({
          type: "success",
          message: "Document updated successfully",
          open: true,
          timeout: 4000,
        });
        if (case_id) {
          navigate(`/cases/${case_id}`);
        }
        navigate("/cases");
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "error",
          message: err,
          open: true,
          timeout: 4000,
        });
      });
  };

  const handleDownload = async () => {
    try {
      axios({
        method: "GET",
        url: documentDownloadPrefix(organization_id, case_id, document_id),
        responseType: "blob",
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          const ext = res.headers["content-type"].split("/")[1];
          link.setAttribute("download", `${formState.title}.${ext}`);
          document.body.appendChild(link);
          link.click();
          dispatchAlert({
            type: "success",
            message: "Document downloaded successfully",
            open: true,
            timeout: 4000,
          });
        })
        .catch((err) => {
          console.error(err);
          dispatchAlert({
            type: "danger",
            message: err,
            open: true,
            timeout: 4000,
          });
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Edit Document"
          subtitle="Documents"
          className="text-sm-left"
        />
        <DeleteButton
          endpoint={documentsDisablePrefix(
            organization_id,
            case_id,
            document_id
          )}
          redirect_to="/documents"
        />
      </Row>
      <Row>
        <Col>{loading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Documents Details</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/*document_title*/}
                        <Col md="6" className="form-group">
                          <label htmlFor="document_title">Document Title</label>
                          <FormInput
                            id="document_title"
                            name="title"
                            placeholder="Document Title"
                            required
                            value={formState.title || ""}
                            onChange={handleChange}
                          />
                        </Col>
                        {/*document_case_id*/}
                        <Col md="6" className="form-group">
                          <label htmlFor="document_case_id">Case ID</label>
                          <FormSelect
                            id="document_case_id"
                            placeholder="Case ID"
                            name="case_id"
                            required
                            value={formState.case_id || ""}
                            onChange={handleChange}
                            disabled
                          >
                            <option value="">Select Case ID</option>
                            {cases.map((caseItem) => (
                              <option key={caseItem.id} value={caseItem.id}>
                                {caseItem.id}
                              </option>
                            ))}
                          </FormSelect>
                        </Col>
                        {/*document_court_category*/}
                        {/* <Col md="4" className="form-group">
                          <label htmlFor="document_court_category">
                            Court Category
                          </label>
                          <FormSelect
                            id="document_court_category"
                            placeholder="Court Category"
                            name="court_category"
                            value={formState.court_category || ""}
                            required
                            onChange={handleChange}
                          >
                            <option value="">Select Court Category</option>
                            <option value="high-court">High Court</option>
                            <option value="supreme-court">Supreme Court</option>
                            <option value="magistrate-court">
                              Magistrate Court
                            </option>
                          </FormSelect>
                        </Col> */}
                      </Row>
                      {/* <Row>
                        <Col md="12" className="form-group">
                          <label htmlFor="file_upload">Upload File</label>
                          <FormInput
                            id="file_upload"
                            type="file"
                            name="file"
                            required
                            onChange={(e) => {
                              setFormState({
                                ...formState,
                                file: e.target.files[0],
                              });
                            }}
                          />
                        </Col>
                      </Row> */}

                      {errors.length > 0 && (
                        <Row form>
                          <p style={{ marginLeft: "6px" }}>
                            Please fill out the following fields:{" "}
                            <span style={{ fontWeight: "500" }}>
                              {errors.map((err) => err.name).join(", ")}
                            </span>
                          </p>
                        </Row>
                      )}
                      <Row form>
                        <Col md={2} className="form-group">
                          <Button
                            disabled={errors.length > 0}
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        </Col>
                        <Col md={2} className="form-group">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleDownload}
                          >
                            Download
                          </button>
                        </Col>
                        <Col md={2} className="form-group">
                          <Button
                            type="button"
                            className="btn-secondary"
                            style={{ marginLeft: "14px" }}
                            onClick={(e) => {
                              navigate(-1);
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col md={2} className="form-group">
                          <DeleteButton
                            endpoint={documentsDisablePrefix(
                              organization_id,
                              case_id,
                              document_id
                            )}
                            redirect_to="/documents"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DocumentEdit;
