import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "shards-react";

function ConfirmModal({ children, onConfirm, style, confirmMessage }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClick = (e) => {
    if (onConfirm) {
      onConfirm(e);
    }
    setIsOpen(false);
  };

  return (
    <div style={style || {}}>
      <span onClick={toggle}>{children}</span>
      <Modal open={isOpen} toggle={toggle}>
        <ModalHeader>Please Confirm</ModalHeader>
        <ModalBody>{confirmMessage}</ModalBody>
        <ModalFooter>
          <Button theme="danger" onClick={toggle}>
            Cancel
          </Button>
          <Button theme="success" onClick={handleClick}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
