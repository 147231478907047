const validateForm = (formState, requiredFields) => {
  let errors = [];

  requiredFields.forEach((field) => {
    if (formState[field] === null || formState[field] === "") {
      errors.push({
        name: field,
        message: `${field} is required`,
        formatedName: formatKey(field),
      });
    }
  });
  return errors;
};

const formatKey = (key) => {
  // change _ to space and uppercase all first letters
  return key.replace(/_/g, " ").replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export default validateForm;
