import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import UserContext, { UserProvider } from "./contexts/UserContext";
import { AlertProvider } from "./contexts/AlertContext";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <AlertProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </AlertProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
