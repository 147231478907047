import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useUser from "../../hooks/useUser";
import axios from "../../libs/axios";
import UpcomingHearings from "../../components/Dashboard/UpcomingHearings";
import PendingRecievedCases from "../../components/Dashboard/PendingRecievedCases";
import CasesPendingCourtAcceptance from "../../components/Dashboard/CasesPendingCourtAcceptance";
import UnfiledAcceptedCases from "../../components/Dashboard/UnfiledAcceptedCases";
import CasesWithNoCurrentHearingDate from "../../components/Dashboard/CasesWithNoCurrentHearingDate";
import PendingOnHoldCases from "../../components/Dashboard/PendingOnHoldCases";
import PendingDismissalRequests from "../../components/Dashboard/PendingDismissalRequests";
import PendingWritRequests from "../../components/Dashboard/PendingWritRequests";
import Messages from "../../components/Dashboard/Messages";

function Dashboard() {
  const { user, hasRole } = useUser();
  const [dashboard, setDashboard] = useState({
    cases_pending_court_acceptance: [],
    cases_with_no_current_hearing_date: [],
    pending_dismissal_requests: [],
    pending_on_hold_cases: [],
    pending_received_cases: [],
    pending_writ_requests: [],
    unfiled_accepted_cases: [],
    upcoming_hearings: [],
  });

  const shouldDisplay = hasRole("property-manager");

  useEffect(() => {
    if (!shouldDisplay) return;
    axios.get("/dashboard").then((res) => {
      setDashboard(res.data.data);
    });
  }, [shouldDisplay]);

  if (!shouldDisplay) {
    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="py-4">
            <Col lg="12 p-2">
              <Card small className="mb-4">
                <p>Coming soon!</p>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div css={css``}>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={`Hello, ${user.name}`}
            subtitle="Dashboard"
            md="12"
            className="ml-sm-auto mr-sm-auto"
          />
        </Row>
        <Row>
          <Col lg="12">
            <Card small className="mb-4">
              <UpcomingHearings
                upcomingHearings={dashboard.upcoming_hearings}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          {hasRole("paralegal") && (
            <Col sm="12" lg="6">
              <Card small className="mb-4">
                <PendingRecievedCases
                  pendingRecievedCases={dashboard.pending_received_cases}
                />
              </Card>
            </Col>
          )}
          <Col sm="12" lg="6">
            <Card small className="mb-4">
              <CasesPendingCourtAcceptance
                casesPendingCourtAcceptance={
                  dashboard.cases_pending_court_acceptance
                }
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6">
            <Card small className="mb-4">
              <UnfiledAcceptedCases
                unfiledAcceptedCases={dashboard.unfiled_accepted_cases}
              />
            </Card>
          </Col>
        </Row>
        {hasRole("paralegal") && (
          <Row>
            <Col sm="12">
              <Card small className="mb-4">
                <CasesWithNoCurrentHearingDate
                  casesWithNoCurrentHearingDate={
                    dashboard.cases_with_no_current_hearing_date
                  }
                />
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col lg="12">
            <Card small className="mb-4">
              <PendingOnHoldCases
                pendingOnHoldCases={dashboard.pending_on_hold_cases}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card small className="mb-4">
              <PendingDismissalRequests
                pendingDismissalRequests={dashboard.pending_dismissal_requests}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card small className="mb-4">
              <PendingWritRequests
                pendingWritRequests={dashboard.pending_writ_requests}
              />
            </Card>
          </Col>
        </Row>
        {!hasRole("paralegal") && (
          <Row>
            <Col lg="12">
              <Card small className="mb-4">
                <Messages messages={dashboard.messages || []} />
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default Dashboard;
