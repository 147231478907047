/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import stateTitleCase from "../../vars/stateTitleCase";
import axios from "../../libs/axios";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/alerts";
import { getUser, getValidation } from "../../vars/fetching";
import validateForm from "../../vars/validateForm";
import DeleteButton from "../../components/common/DeleteButton";
import { ownersDisablePrefix } from "../../vars/apiRoutePrefixes";

const initialFormState = {
  organization_id: "",
  business_legal_name: "",
  business_dba: "",
  street_address: "",
  city: "",
  state: "",
  zip: "",
  caption_language: "",
  petition_body_language: "",
};

const PropertyOwnerEdit = () => {
  let params = useParams();
  const [formState, setFormState] = useState(initialFormState);
  const [formLoading, setFormLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [createdBy, setCreatedBy] = useState({
    id: "",
    name: "",
  });

  const { dispatchAlert } = useAlert();

  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getValidation("owners", { organization_id: 3000 }).then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  useEffect(() => {
    axios
      .get("/organizations")
      .then((response) => {
        if (response.data.errors?.length > 0) {
          dispatchAlert({
            type: "error",
            message: response.data.errors[0].detail,
            open: true,
            timeout: 4000,
          });
          return;
        }
        setOrganizations(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        dispatchAlert({
          type: "error",
          message: "Error fetching organizations",
          open: true,
          timeout: 4000,
        });
      })
      .finally(() => {
        setFormLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOwner();
    return () => {
      setFormState(initialFormState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.ownerId, params.organizationId]);

  //  get owner
  const getOwner = () => {
    axios
      .get(`/organizations/${params.organizationId}/owners/${params.ownerId}`)
      .then((res) => {
        setFormState({ ...formState, ...res.data.data });
        getUser(res.data.data.created_by).then((user) => {
          setCreatedBy({
            id: user.id,
            name: user.name,
          });
        });
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: "Error getting property owner: " + err.message,
          open: true,
          timeout: 5000,
        });
        if (err.response.status === 404) {
          navigate("/owners");
        }
      });
  };

  //  handleChange for form state
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  //  handleSubmit for form state
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormLoading(true);

    if (errors.length) {
      setFormLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    //  post to organizations
    axios
      .put(
        `/organizations/${params.organizationId}/owners/${formState.id}`,
        formState
      )
      .then((res) => {
        if (res.data.errors?.length > 0) {
          dispatchAlert({
            type: "danger",
            message: res.data.errors[0].detail,
            open: true,
            timeout: 4000,
          });
          return;
        }
        dispatchAlert({
          type: "success",
          message: "Owner updated successfully",
          open: true,
          timeout: 4000,
        });
        navigate("/owners");
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: "Error updating owner",
          open: true,
          timeout: 4000,
        });
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Edit Property Owner"
          subtitle="Owner management"
          className="text-sm-left"
        />
        <DeleteButton
          endpoint={ownersDisablePrefix(params.organizationId, formState.id)}
          redirect_to="/owners"
        />
      </Row>
      <Row>
        <Col>{formLoading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <Form className="p-4">
              <Row>
                <Col className="form-group">
                  <label
                    style={{
                      fontSize: "24px",
                    }}
                  >
                    Meta Information
                  </label>
                </Col>
              </Row>
              <Row form>
                <Col md="4" className="form-group">
                  <label htmlFor="feCreatedBy">Creator ID</label>
                  <FormInput
                    id="feCreatedBy"
                    required
                    name="created_by"
                    value={createdBy.id || ""}
                    disabled
                  />
                </Col>
                <Col md="4" className="form-group">
                  <label htmlFor="feCreatedBy">Creator Name</label>
                  <FormInput
                    id="feCreatedBy"
                    required
                    name="created_by"
                    value={createdBy.name || ""}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col className="form-group">
                  <label
                    style={{
                      fontSize: "24px",
                      marginTop: "24px",
                    }}
                  >
                    Owner Information
                  </label>
                </Col>
              </Row>
              <Row form>
                {/*business_name */}
                <Col md="4" className="form-group">
                  <label htmlFor="feBusinessName">Business Legal Name</label>
                  <FormInput
                    id="feBusinessName"
                    name="business_legal_name"
                    value={formState.business_legal_name || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*business_dba_name*/}
                <Col md="4" className="form-group">
                  <label htmlFor="feBusinessDbaName">Business DBA Name</label>
                  <FormInput
                    id="feBusinessDbaName"
                    name="business_dba"
                    value={formState.business_dba || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*property_manager*/}
                <Col md="4" className="form-group">
                  <label htmlFor="fePropertyManager">Management Organization</label>
                  <FormSelect
                    id="fePropertyManager"
                    name="organization_id"
                    value={formState.organization_id || ""}
                    disabled
                  >
                    <option value="">Select Organization</option>
                    {organizations.map((organization) => (
                      <option key={organization.id} value={organization.id}>
                        {organization.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/*address*/}
                <Col md="12" className="form-group">
                  <label htmlFor="feAddress">Address</label>
                  <FormInput
                    id="feAddress"
                    required
                    name="street_address"
                    value={formState.street_address || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*city*/}
                <Col md="5" className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormInput
                    id="feCity"
                    required
                    name="city"
                    value={formState.city || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*state*/}
                <Col md="4" className="form-group">
                  <label htmlFor="feState">State</label>
                  <FormSelect
                    id="feState"
                    required
                    name="state"
                    value={formState.state || ""}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Choose State
                    </option>
                    {stateTitleCase.map((state) => (
                      <option key={state.name} value={state.abbreviation}>
                        {state.abbreviation} - {state.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/*zip*/}
                <Col md="3" className="form-group">
                  <label htmlFor="feZip">Zip</label>
                  <FormInput
                    id="feZip"
                    name="zip"
                    value={formState.zip || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*caption_language*/}
                <Col md="4" className="form-group">
                  <label htmlFor="feCaptionLanguage">Caption Language</label>
                  <FormInput
                    id="feCaptionLanguage"
                    required
                    name="caption_language"
                    value={formState.caption_language || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*petition_body_language*/}
                <Col md="4">
                  <label htmlFor="fePetitionBodyLanguage">
                    Petition Body Language
                  </label>
                  <FormInput
                    id="fePetitionBodyLanguage"
                    required
                    name="petition_body_language"
                    value={formState.petition_body_language || ""}
                    onChange={handleChange}
                  />
                </Col>
                {/*created_by*/}
              </Row>
              {errors.length > 0 && (
                <Row form>
                  <p style={{ marginLeft: "6px" }}>
                    Please fill out the following fields:{" "}
                    <span style={{ fontWeight: "500" }}>
                      {errors.map((err) => err.name).join(", ")}
                    </span>
                  </p>
                </Row>
              )}
              <Row form>
                <Col md={1} className="form-group">
                  <Button disabled={errors.length > 0} onClick={handleSubmit}>
                    Save
                  </Button>
                </Col>
                <Col md={2} className="form-group">
                  <Button
                    type="button"
                    className="btn-secondary"
                    style={{ marginLeft: "14px" }}
                    onClick={(e) => {
                      navigate("/owners");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={1} className="form-group">
                  <DeleteButton
                    endpoint={ownersDisablePrefix(
                      params.organizationId,
                      formState.id
                    )}
                    redirect_to="/owners"
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PropertyOwnerEdit;
