/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";
import Select from "react-select";
import PageTitle from "../../components/common/PageTitle";
import axios from "../../libs/axios";
import { getOrganizations, getRoles, getValidation } from "../../vars/fetching";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/alerts";
import validateForm from "../../vars/validateForm";

const initialFormState = {
  name: "",
  email: "",
  password: "",
  role_id: null,
  password_confirmation: "",
  organization_id: null,
  owner_ids: [],
  phone: "",
  active: "",
};

const UserAdd = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [owners, setOwners] = useState([]);
  const [roles, setRoles] = useState([]);
  // const organization_id =
  //   user.organizations?.length > 0 && user.organizations[0]?.id;

  const { dispatchAlert } = useAlert();

  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);
  const [multiState, setMultiState] = useState([]);
  const [allOwners, setAllOwners] = useState(0);

  useEffect(() => {
    getValidation("users").then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  //  handle change
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    getOrganizations().then((organizations) => {
      if (organizations.errors) {
        dispatchAlert({
          type: "danger",
          message: organizations.message,
          open: true,
          timeout: 4000,
        });
        return;
      }
      setOrganizations([...organizations]);
    });
    if (!roles.length) {
      getRoles()
        .then((roles) => {
          setRoles(roles);
        })
        .catch((err) => {
          console.error(err);
          dispatchAlert({
            type: "danger",
            message: err.message,
            open: true,
            timeout: 5000,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!passwordConfirmed) {
      return;
    }
    setLoading(true);

    if (errors.length) {
      setLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    axios
      .post(`/users`, formState)
      .then((res) => {
        if (res.data.errors?.length > 0) {
          dispatchAlert({
            type: "danger",
            message: res.data.message,
            open: true,
            timeout: 4000,
          });
          setLoading(false);
          return;
        }
        dispatchAlert({
          type: "success",
          message: "User added successfully",
          open: true,
          timeout: 4000,
        });
        navigate("/users/" + res.data.data.id);
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: err,
          open: true,
          timeout: 4000,
        });
      });
  };

  const formatWord = (word) => {
    // Split by - and capitalize each word
    return word
      .split("-")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  useEffect(() => {
    if (formState.organization_id) {
      const owners = organizations.find(
        (org) => Number(org.id) === Number(formState.organization_id)
      )?.owners;
      setOwners(owners);
    }
  }, [formState.organization_id, organizations]);

  useEffect(() => {
    setFormState({
      ...formState,
      owner_ids: [],
    });
    setMultiState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.organization_id]);

  useEffect(() => {
    if (allOwners) {
      setFormState({
        ...formState,
        owner_ids: owners.map((owner) => owner.id),
      });
      setMultiState([]);
    }
    if (!allOwners) {
      setFormState({
        ...formState,
        owner_ids: [],
      });
      setMultiState([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOwners]);

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add User"
          subtitle="Users"
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col>{loading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">User Details</h6>
            </CardHeader>
            <Form className="p-4">
              <Row form>
                {/*name*/}
                <Col md="6" className="form-group">
                  <label htmlFor="name">Name</label>
                  <FormInput
                    id="name"
                    name="name"
                    value={formState.name}
                    onChange={handleChange}
                  />
                </Col>
                {/*email*/}
                <Col md="6" className="form-group">
                  <label htmlFor="email">Email</label>
                  <FormInput
                    id="email"
                    name="email"
                    value={formState.email}
                    onChange={handleChange}
                  />
                </Col>
                {/*password*/}
                <Col md="6" className="form-group">
                  <label htmlFor="password">Password</label>
                  <FormInput
                    id="password"
                    type="password"
                    name="password"
                    value={formState.password}
                    onChange={handleChange}
                  />
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="confirm_password">Confirm Password</label>
                  <FormInput
                    id="confirm_password"
                    type="password"
                    name="confirm_password"
                    onChange={(e) => {
                      setPasswordConfirmed(
                        e.target.value === formState.password
                      );
                      setFormState({
                        ...formState,
                        password_confirmation: e.target.value,
                      });
                    }}
                  />
                  {!passwordConfirmed && (
                    <span className="text-danger">
                      * Passwords do not match
                    </span>
                  )}
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="role_id">Role</label>
                  <FormSelect
                    id="role_id"
                    name="role_id"
                    value={formState.role_id || ""}
                    onChange={handleChange}
                  >
                    <option>Select Role</option>
                    {roles.length > 0 &&
                      roles?.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {formatWord(role.name)}
                          </option>
                        );
                      })}
                  </FormSelect>
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="organization_id">Management Organization</label>
                  <FormSelect
                    id="organization_id"
                    name="organization_id"
                    value={formState.organization_id || ""}
                    onChange={handleChange}
                  >
                    <option value={""}>Select Organization</option>
                    {organizations.length > 0 &&
                      organizations?.map((organization) => {
                        return (
                          <option key={organization.id} value={organization.id}>
                            {formatWord(organization.name)}
                          </option>
                        );
                      })}
                  </FormSelect>
                </Col>
              </Row>
              <Row form>
                <Col md={6} className="form-group">
                  <label htmlFor="all_owners">Select All Owners?</label>
                  <FormSelect
                    id="all_owners"
                    name="all_owners"
                    value={allOwners}
                    onChange={(e) => {
                      setAllOwners(Number(e.target.value));
                    }}
                    disabled={!formState.organization_id}
                  >
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </FormSelect>
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="owners">Owners</label>
                  {
                    <Select
                      id="owners"
                      name="owners"
                      isMulti
                      options={owners?.map((own) => {
                        return {
                          value: own.id,
                          label: own.business_legal_name,
                        };
                      })}
                      value={multiState}
                      onChange={(e) => {
                        setFormState({
                          ...formState,
                          owner_ids: e.map((owner) => owner.value),
                        });
                        setMultiState(e);
                      }}
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          borderColor: "#e1e5eb",
                        }),
                      }}
                      isDisabled={allOwners || !formState.organization_id}
                    />
                  }
                </Col>
              </Row>
              <Row form>
                <Col md={6} className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <FormInput
                    id="phone"
                    name="phone"
                    value={formState.phone || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="active">Active</label>
                  <FormSelect
                    id="active"
                    name="active"
                    value={formState.active || ""}
                    onChange={handleChange}
                  >
                    <option value={""}>Select an Option...</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </FormSelect>
                </Col>
              </Row>
              {errors.length > 0 && (
                <Row form>
                  <p style={{ marginLeft: "6px" }}>
                    Please fill out the following fields:{" "}
                    <span style={{ fontWeight: "500" }}>
                      {errors.map((err) => err.name).join(", ")}
                    </span>
                  </p>
                </Row>
              )}
              <Row form>
                <Col md={1}>
                  <Button disabled={errors.length > 0} onClick={handleSubmit}>
                    Save
                  </Button>
                </Col>
                <Col md={1} className="form-group">
                  <Button
                    type="button"
                    className="btn-danger"
                    style={{ marginLeft: "14px" }}
                    onClick={(e) => {
                      navigate("/users");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserAdd;
