import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "shards-react";

function OptionModal({
  style,
  children,
  message,
  buttons = [],
  ChildrenContainer,
  ModalContainer,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClick = (action) => {
    if (action) {
      action();
    }
    setIsOpen(false);
  };

  const RealChildrenContainer = ChildrenContainer
    ? ({ children }) => (
        <ChildrenContainer onClick={toggle}>{children}</ChildrenContainer>
      )
    : ({ children }) => (
        <React.Fragment onClick={toggle}>{children}</React.Fragment>
      );

  const RealModalContainer = ModalContainer
    ? ({ children }) => <ModalContainer>{children}</ModalContainer>
    : ({ children }) => <React.Fragment>{children}</React.Fragment>;
  
  const filteredButtons = buttons.filter((b) => {
    return !b.invisible;
  });

  return (
    <React.Fragment>
      <RealChildrenContainer onClick={toggle}>{children}</RealChildrenContainer>
      <RealModalContainer>
        <Modal open={isOpen} toggle={toggle}>
          <ModalHeader>Please Choose</ModalHeader>
          <ModalBody>{message}</ModalBody>
          <ModalFooter>
            <Button theme="secondary" onClick={toggle}>
              Cancel
            </Button>
            {filteredButtons.length > 0 ?
              filteredButtons
              .map(({ text, classes, action }) => {
                return (
                  <Button
                    key={text}
                    onClick={() => handleClick(action)}
                    className={classes}
                  >
                    {text}
                  </Button>
                );
              })
              : null
            }
          </ModalFooter>
        </Modal>
      </RealModalContainer>
    </React.Fragment>
  );
}

export default OptionModal;
