/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Link } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { getOwners } from "../../vars/fetching";
import useAlert from "../../hooks/alerts";
import TableView from "../../components/Table/Table";

const PropertyOwners = () => {
  const [owners, setOwners] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const { user } = useUser();
  const organization_id =
    user.organizations?.length > 0 && user.organizations[0]?.id;
  const { dispatchAlert } = useAlert();

  useEffect(() => {
    getOwners(organization_id).then((res) => {
      if (res.error) {
        dispatchAlert({
          type: "error",
          message: res.data.data.error,
          open: true,
          timeout: 4000,
        });
        return;
      }
      setOwners(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatAddress = (propertyOwner) => {
    // {propertyOwner.street_address}{" "}
    // {propertyOwner.zip}
    // {propertyOwner.city},{propertyOwner.state}{" "}
    const { street_address, city, zip, state } = propertyOwner;
    let addString = "";
    if (street_address) {
      addString += street_address;
    }

    if (zip) {
      addString += " " + zip;
    }

    if (city) {
      addString += ", " + city;
    }

    if (state) {
      addString += ", " + state;
    }

    return addString;
  };

  const columns = [
    {
      name: "#",
    },
    {
      name: "Business Name",
    },
    {
      name: "Address",
    },
    {
      name: "Management Organization"
    },
  ];

  const formattedOwners = owners.map((owner, index) => {
    return {
      id: owner.id,
      endpoint: `/owners/${owner.id}/${owner.organization_id}`,
      api_endpoint: `/organizations/${organization_id}/owners/${owner.id}`,
      fullRecord: owner,
      columns: [
        {
          display: index + 1,
          value: index + 1,
        },
        {
          display: owner.business_legal_name,
          value: owner.business_legal_name,
        },
        {
          display: formatAddress(owner),
          value: formatAddress(owner),
        },
        {
          display: owner.organization.name,
          value: owner.organization.id,
        },
      ],
    };
  });

  if (loading) {
    return <div className="loader mt-7" />;
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Property Owners"
          subtitle="Property Owners"
          className=""
        />
        <Link className="btn btn-primary btn-lg" to="/owners/add">
          New Property Owner
        </Link>
      </Row>
      <br />

      <Row>
        <Col>
          <TableView
            name="Property Owners"
            columns={columns}
            rows={formattedOwners}
            refreshTable={getOwners}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PropertyOwners;
