/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";
import axios from "../../libs/axios";
import PageTitle from "../../components/common/PageTitle";
import { documentsPostPrefix } from "../../vars/apiRoutePrefixes";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../hooks/alerts";
import { getValidation } from "../../vars/fetching";
import validateForm from "../../vars/validateForm";
import useUser from "../../hooks/useUser";

const initialFormState = {
  title: "",
  case_id: null,
  court_category: "",
  file: null,
};

const DocumentAdd = () => {
  const [cases, setCases] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState(initialFormState);
  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);
  const { user: { organizations } } = useUser()
  const { case_id, organization_id = organizations?.length > 0 && organizations[0].id } = useParams();
  const getCases = () => {
    axios
      .get("/organizations/" + organization_id + "/cases")
      .then((res) => {
        setCases(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getValidation("documents", {
      organization_id: organization_id,
      case_id: case_id ? case_id : 10000,
    }).then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  const { dispatchAlert } = useAlert();

  useEffect(() => {
    setFormState({
      ...formState,
      case_id: case_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  useEffect(() => {
    if (cases === null) {
      getCases();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cases]);

  const navigate = useNavigate();

  const handleSubmit = (e, cb) => {
    e.preventDefault();
    setLoading(true);

    if (errors.length) {
      setLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    const formData = new FormData();
    Object.keys(formState).forEach((key) => {
      formData.append(key, formState[key]);
    });

    axios
      .post(documentsPostPrefix(organization_id, formState.case_id), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.errors?.length > 0) {
          dispatchAlert({
            open: true,
            message: res.data.message,
            type: "danger",
            timeout: 5000,
          });
        }
        dispatchAlert({
          open: true,
          message: "Document added successfully",
          type: "success",
          timeout: 4000,
        });
        cb();
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          open: true,
          message: err,
          type: "danger",
          timeout: 4000,
        });
      });
  };

  const formatCaseName = (caseItem) => {
    let output = `[${caseItem.id}] `;
    if (caseItem.residents[0]?.first_name) {
      output += caseItem.residents[0].first_name + " ";
    }
    if (caseItem.residents[0]?.last_name) {
      output += caseItem.residents[0].last_name;
    }
    if (caseItem.street_address) {
      output += " - " + caseItem.street_address;
    }
    return output;
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add Document"
          subtitle="Documents"
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col>{loading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Documents Details</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/*document_title*/}
                        <Col md="6" className="form-group">
                          <label htmlFor="document_title">Document Title</label>
                          <FormInput
                            id="document_title"
                            name="title"
                            placeholder="Document Title"
                            required
                            value={formState.title || ""}
                            onChange={handleChange}
                          />
                        </Col>
                        {/*document_case_id*/}
                        <Col md="6" className="form-group">
                          <label htmlFor="document_case_id">Case ID</label>
                          <FormSelect
                            id="document_case_id"
                            placeholder="Case ID"
                            name="case_id"
                            required
                            value={formState.case_id || case_id || ""}
                            onChange={handleChange}
                            disabled={case_id?.length > 0}
                          >
                            <option value="">Select Case</option>
                            {cases?.map((caseItem) => (
                              <option key={caseItem.id} value={caseItem.id}>
                                {formatCaseName(caseItem)}
                              </option>
                            ))}
                          </FormSelect>
                        </Col>
                        {/*document_court_category*/}
                        {/* <Col md="4" className="form-group">
                          <label htmlFor="document_court_category">
                            Court Category
                          </label>
                          <FormSelect
                            id="document_court_category"
                            placeholder="Court Category"
                            name="court_category"
                            value={formState.court_category || ""}
                            required
                            onChange={handleChange}
                          >
                            <option value="">Select Court Category</option>
                            <option value="high-court">High Court</option>
                            <option value="supreme-court">Supreme Court</option>
                            <option value="magistrate-court">
                              Magistrate Court
                            </option>
                          </FormSelect>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col md="12" className="form-group">
                          <label htmlFor="file_upload">Upload File</label>
                          <FormInput
                            id="file_upload"
                            type="file"
                            name="file"
                            required
                            onChange={(e) => {
                              setFormState({
                                ...formState,
                                file: e.target.files[0],
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      {errors.length > 0 && (
                        <Row form>
                          <p style={{ marginLeft: "6px" }}>
                            Please fill out the following fields:{" "}
                            <span style={{ fontWeight: "500" }}>
                              {errors.map((err) => err.name).join(", ")}
                            </span>
                          </p>
                        </Row>
                      )}
                      <Row form>
                        <Col className="form-group">
                          <Button
                            disabled={errors.length > 0}
                            onClick={(event) => {
                              handleSubmit(event, () => {
                                navigate(`/cases/${case_id}/${organization_id}?highlight=documents`);
                              });
                            }}
                            title="Save and view new document"
                          >
                            Save
                          </Button>
                          <Button
                            disabled={errors.length > 0}
                            style={{ marginLeft: "14px" }}
                            onClick={(event) => {
                              handleSubmit(event, () => {
                                navigate("/cases");
                              });
                            }}
                            title="Save and exit to cases view"
                          >
                            Save and Exit
                          </Button>
                          <Button
                            disabled={errors.length > 0}
                            style={{ marginLeft: "14px" }}
                            onClick={(event) => {
                              handleSubmit(event, () => {
                                if (case_id) {
                                  navigate(`/cases/${case_id}/${organization_id}?highlight=documents`);
                                }
                              });
                            }}
                            title="Save and return to case view"
                          >
                            Save and Return
                          </Button>
                          <Button
                            type="button"
                            className="btn-danger"
                            style={{ marginLeft: "14px" }}
                            onClick={(e) => {
                              navigate(-1);
                            }}
                            title="Cancel and return to previous page"
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DocumentAdd;
