import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Constants } from "../../../flux";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const SidebarMainNavbar = ({ hideLogoText }) => {
  const handleToggleSidebar = () => {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  };

  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <NavbarBrand
          className="w-100 mr-0"
          href="/"
          style={{ lineHeight: "25px" }}
        >
          <div className="d-table m-auto">
            {/* <img
                id="main-logo"
                className="d-inline-block align-top mr-1"
                style={{ maxWidth: "25px" }}
                src={logo}
                alt="Shards Dashboard"
              /> */}
            {!hideLogoText && (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 0;

                  .company {
                    font-size: 0.7rem;
                    font-weight: 400;
                    margin: 0;
                    line-height: 1.2;
                  }

                  .name {
                    font-size: 0.8rem;
                    font-weight: 500;
                    margin: 0;
                    line-height: 1.5;
                  }
                `}
              >
                <p className="company">Bassin, Young & Barjenbruch</p>
                <p className="name">Client Portal</p>
              </div>
            )}
          </div>
        </NavbarBrand>
        {/* eslint-disable-next-line */}
        <a
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          onClick={handleToggleSidebar}
        >
          <i className="material-icons">&#xE5C4;</i>
        </a>
      </Navbar>
    </div>
  );
};

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;
