/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  Form,
  FormInput,
  FormSelect,
} from "shards-react";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import {
  organizationsDisablePrefix,
  organizationsPutPrefix,
} from "../../vars/apiRoutePrefixes";
import states from "../../vars/stateTitleCase";
import axios from "../../libs/axios";
import useAlert from "../../hooks/alerts";

import { useNavigate } from "react-router-dom";
import { getValidation } from "../../vars/fetching";
import validateForm from "../../vars/validateForm";
import DeleteButton from "../../components/common/DeleteButton";

const initialFormState = {
  name: "",
  street_address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  primary_contact_id: "",
  invoice_code: "",
};

const PropertyManagerEdit = () => {
  let params = useParams();
  const [formState, setFormState] = useState(initialFormState);
  const [users, setUsers] = useState([]);

  const { dispatchAlert } = useAlert();
  const [formLoading, setFormLoading] = useState(false);

  const [requiredFields, setRequiredFields] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getValidation("organizations").then((res) => {
      const newRequiredFields = Object.keys(res.rules).filter((key) =>
        res.rules[key].includes("required")
      );
      setRequiredFields(newRequiredFields);
      setErrors(validateForm(formState, newRequiredFields));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(validateForm(formState, requiredFields));
  }, [formState, requiredFields]);

  //  get organization
  const getPropertyManager = () => {
    axios
      .get(organizationsPutPrefix(params.organizationId))
      .then((res) => {
        if (res.errors?.length) {
          dispatchAlert({
            type: "danger",
            message: res.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        setFormState({ ...formState, ...res.data.data });
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          type: "danger",
          message: "Error getting management organization: " + err.message,
          open: true,
          timeout: 5000,
        });
        if (err.response.status === 404) {
          navigate("/organizations");
        }
      });
  };

  useEffect(() => {
    getPropertyManager();
    return () => {
      setFormState(initialFormState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.organizationId]);

  useEffect(() => {
    // get owners
    if (!users.length) {
      axios
        .get("/users")
        .then((response) => {
          if (response.data.errors?.length > 0) {
            dispatchAlert({
              type: "danger",
              message: response.data.message,
              open: true,
              timeout: 4000,
            });
            return;
          }
          setUsers(response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });

  //  handleChange for form state
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  //  handleSubmit for form state
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormLoading(true);

    if (errors.length) {
      setFormLoading(false);
      dispatchAlert({
        type: "danger",
        message:
          "Please fill out required fields: " +
          errors.map((err) => err.formatedName).join(", "),
        open: true,
        timeout: 5000,
      });
      return;
    }

    //  post to organizations
    axios
      .put(organizationsPutPrefix(params.organizationId), formState)
      .then((res) => {
        if (res.errors?.length) {
          dispatchAlert({
            type: "danger",
            message: res.message,
            open: true,
            timeout: 4000,
          });
          return;
        }
        dispatchAlert({
          type: "success",
          message: `Management Organization ${res.data.data.name} was successfully updated.`,
          open: true,
          timeout: 4000,
        });
        navigate("/organizations");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Edit Management Organization"
          subtitle="Property management"
          className="text-sm-left"
        />
        <DeleteButton
          endpoint={organizationsDisablePrefix(params.organizationId)}
          redirect_to="/organizations"
        />
      </Row>
      <Row>
        <Col>{formLoading && <div className="loader" />}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Management Organization Details</h6>
            </CardHeader>
            <Form className="p-4">
              <Row form>
                {/*business_name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feBusinessName">Business Name</label>
                  <FormInput
                    id="feBusinessName"
                    name="name"
                    value={formState.name || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*business__primary_contact*/}
                {users.length && (
                  <Col md="6" className="form-group">
                    <label htmlFor="feBusinessPrimaryContact">
                      Primary Contact ID
                    </label>
                    <FormSelect
                      id="feBusinessPrimaryContact"
                      name="primary_contact_id"
                      value={formState.primary_contact_id || ""}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select a Primary Contact</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                )}
                {/*business_address*/}
                <Col md="12" className="form-group">
                  <label htmlFor="feBusinessAddress">Business Address</label>
                  <FormInput
                    id="feBusinessAddress"
                    name="street_address"
                    value={formState.street_address || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*business_city*/}
                <Col md="5" className="form-group">
                  <label htmlFor="feBusinessCity">Business City</label>
                  <FormInput
                    id="feBusinessCity"
                    name="city"
                    value={formState.city || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*business_state*/}
                <Col md="4" className="form-group">
                  <label htmlFor="feBusinessState">Business State</label>
                  <FormSelect
                    id="feBusinessState"
                    name="state"
                    value={formState.state || ""}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Choose State
                    </option>
                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.abbreviation} - {state.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                {/*business_zip*/}
                <Col md="3" className="form-group">
                  <label htmlFor="feBusinessZip">Business Zip</label>
                  <FormInput
                    id="feBusinessZip"
                    name="zip"
                    value={formState.zip || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*business_phone*/}
                <Col md="6" className="form-group">
                  <label htmlFor="feBusinessPhone">Business Phone</label>
                  <FormInput
                    id="feBusinessPhone"
                    name="phone"
                    value={formState.phone || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>

                {/*business_invoice_code*/}
                <Col md="6" className="form-group">
                  <label htmlFor="feBusinessInvoiceCode">
                    Business Invoice Code
                  </label>
                  <FormInput
                    id="feBusinessInvoiceCode"
                    name="invoice_code"
                    value={formState.invoice_code || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
              {errors.length > 0 && (
                <Row form>
                  <p style={{ marginLeft: "6px" }}>
                    Please fill out the following fields:{" "}
                    <span style={{ fontWeight: "500" }}>
                      {errors.map((err) => err.name).join(", ")}
                    </span>
                  </p>
                </Row>
              )}
              <Row form>
                <Col md={1} className="form-group">
                  <Button disabled={errors.length > 0} onClick={handleSubmit}>
                    Save
                  </Button>
                </Col>
                <Col md={2} className="form-group">
                  <Button
                    type="button"
                    className="btn-secondary"
                    style={{ marginLeft: "14px" }}
                    onClick={(e) => {
                      navigate("/organizations");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={1} className="form-group">
                  <DeleteButton
                    endpoint={organizationsDisablePrefix(params.organizationId)}
                    redirect_to="/organizations"
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PropertyManagerEdit;
