import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Link } from "react-router-dom";
import axios from "../../libs/axios";
import useAlert from "../../hooks/alerts";
import TableView from "../../components/Table/Table";

function Judges() {
  //  judges
  const [judges, setJudges] = useState([]);
  //  loading
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getJudges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { dispatchAlert } = useAlert();

  //  get judges
  const getJudges = () => {
    axios
      .get("/judges")
      .then((res) => {
        if (res.data.data.error) {
          dispatchAlert({
            open: true,
            type: "danger",
            message: res.data.data.message,
            timeout: 4000,
          });
          return;
        }
        setJudges(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          open: true,
          message: err,
          type: "danger",
          timeout: 4000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatName = (obj) => {
    const { first_name, last_name, suffix } = obj;
    let addString = "";
    if (first_name) {
      addString += first_name;
    }

    if (last_name) {
      addString += " " + last_name;
    }

    if (suffix) {
      addString += " " + suffix;
    }

    return addString;
  };

  const columns = [
    {
      name: "#",
    },
    {
      name: "Name",
    },
    {
      name: "Division",
    },
  ];

  const rows = judges.map((judge) => {
    return {
      id: judge.id,
      endpoint: `/judges/${judge.id}/${judge.jurisdiction_id}`,
      api_endpoint: `/jurisdictions/${judge.jurisdiction_id}/judges/${judge.id}`,
      fullRecord: judge,
      columns: [
        {
          display: judge.id,
          value: judge.id,
        },
        {
          display: formatName(judge),
          value: formatName(judge),
        },
        {
          display: judge.division,
          value: judge.division,
        },
      ],
    };
  });

  if (loading) {
    return <div className="loader mt-7" />;
  }
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Judges"
          subtitle="Judges management"
          className=""
        />
        <Link className="btn btn-primary btn-lg" to="/judges/add">
          New Judge
        </Link>
      </Row>
      <br />

      <Row>
        <Col>
          <TableView
            name="Judges"
            columns={columns}
            rows={rows}
            refreshTable={getJudges}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Judges;
