/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Link } from "react-router-dom";
import axios from "../../libs/axios";
import useAlert from "../../hooks/alerts";
import useUser from "../../hooks/useUser";
import TableView from "../../components/Table/Table";

const PropertyManagers = () => {
  const [propertyManagers, setPropertyManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPropertyManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { hasRole } = useUser();

  const { dispatchAlert } = useAlert();

  const getPropertyManagers = () => {
    axios({ url: "/organizations" })
      .then((res) => {
        if (res.data.data.error) {
          dispatchAlert({
            open: true,
            type: "danger",
            message: res.data.data.error,
            timeout: 4000,
          });
          return;
        }
        setPropertyManagers(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          open: true,
          message: err,
          type: "danger",
          timeout: 4000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatAddress = (propertyManager) => {
    // {propertyManager.street_address}{" "}
    // {propertyManager.zip}
    // {propertyManager.city},{propertyManager.state}{" "}
    const { street_address, city, zip, state } = propertyManager;
    let addString = "";
    if (street_address) {
      addString += street_address;
    }

    if (zip) {
      addString += " " + zip;
    }

    if (city) {
      addString += ", " + city;
    }

    if (state) {
      addString += ", " + state;
    }

    return addString;
  };

  const formattedManagers = propertyManagers.map((p) => {
    return {
      ...p,
      address: formatAddress(p),
    };
  });

  const columns = [
    {
      name: "#",
    },
    {
      name: "Name",
    },
    {
      name: "Address",
    },
  ];

  /**
   * @param {Array} props.rows - the table's rows
   * @param {string} props.rows[].id - the row's id
   * @param {string} props.rows[].endpoint - the row's endpoint
   * @param {Array} props.rows[].columns - the row's columns
   * @param {string} props.rows[].columns[].display - the data to display in the row
   * @param {string} props.rows[].columns[].value - the value of the data
   */
  const rows = formattedManagers.map((p) => {
    return {
      id: p.id,
      endpoint: `/organizations/${p.id}`,
      api_endpoint: `/organizations/${p.id}`,
      fullRecord: p,
      columns: [
        {
          display: p.id,
          value: p.id,
        },
        {
          display: p.name,
          value: p.name,
        },
        {
          display: p.address,
          value: p.address,
        },
      ],
    };
  });

  if (loading) {
    return <div className="loader mt-7" />;
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Management Organizations"
          subtitle="Property management"
          className=""
        />
        {hasRole("superuser") && (
          <Link className="btn btn-primary btn-lg" to="/organizations/add">
            New Management Organization
          </Link>
        )}
      </Row>
      <br />

      <Row>
        <Col>
          <TableView
            name="Management Organizations"
            columns={columns}
            rows={rows}
            refreshTable={getPropertyManagers}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PropertyManagers;
