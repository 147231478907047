import React, { useEffect, useState } from "react";
import { Container, Row, Col, FormCheckbox } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Link } from "react-router-dom";
import axios from "../../libs/axios";
import useUser from "../../hooks/useUser";
import useAlert from "../../hooks/alerts";
import {
  getJurisdictions,
  getOwners,
  getOrganizations,
} from "../../vars/fetching";
import TableView from "../../components/Table/Table";
import { formatDateYearMonthDay } from "../../utils/formatting";

const Cases = () => {
  const [cases, setCases] = useState([]);
  const [formattedCases, setFormattedCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [owners, setOwners] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [jurisdictions, setJurisdictions] = useState([]);

  const { user, hasRole } = useUser();
  const organization_id =
    user.organizations?.length > 0 && user.organizations[0]?.id;

  const { dispatchAlert } = useAlert();

  const getCases = () => {
    axios
      .get(`/organizations/${organization_id}/cases`)
      .then((res) => {
        if (res.data.data.error) {
          dispatchAlert({
            open: true,
            type: "danger",
            message: res.data.data.error,
          });
          return;
        }
        setCases(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        dispatchAlert({
          open: true,
          message: err,
          type: "danger",
          timeout: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCases();
    if (hasRole("property-manager-supervisor")) {
      getOwners().then((owners) => {
        setOwners([...owners]);
      });
    }
    getOrganizations().then((organizations) => {
      setOrganizations([...organizations]);
    });
    if (hasRole("paralegal")) {
      getJurisdictions().then((jurisdictions) => {
        setJurisdictions([...jurisdictions]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormattedJurisdiction = (caseItem) => {
    const jur = jurisdictions.find((jur) => {
      return jur.id === caseItem.jurisdiction_id;
    });
    if (!jur) {
      return "";
    }
    let addString = "";
    if (jur.county_name) {
      addString += jur.county_name;
    }
    if (jur.location_name) {
      addString += " (" + jur.location_name + ")";
    }
    return addString;
  };

  const formatAddress = (caseItem) => {
    // {caseItem.street_address +
    //   `${
    //     caseItem.street_address2
    //       ? ", " + caseItem.street_address2
    //       : ""
    //   }`}
    const { street_address, street_address2 } = caseItem;
    let addString = "";
    if (street_address) {
      addString += street_address;
    }
    if (street_address2) {
      addString += ", " + street_address2;
    }
    return addString;
  };

  const formatResident = (caseItem) => {
    const { residents } = caseItem;
    const resident1 = residents[0];
    if (!resident1) {
      return "";
    }
    let addString = "";

    if (resident1.first_name) {
      addString += resident1.first_name;
    }

    if (resident1.last_name) {
      addString += " " + resident1.last_name;
    }

    return addString;
  };

  const getCaseOwner = (caseItem) => {
    const owner = owners.find((own) => own.id === caseItem.owner_id);
    if (!owner) {
      return "";
    }
    return owner.business_legal_name;
  };

  const getCaseOrganization = (caseItem) => {
    const organization = organizations.find((org) => {
      return org.id === caseItem.organization_id;
    });
    if (!organization) {
      return "";
    }
    return organization.name;
  };

  const getFormattedCase = async (caseItem) => {
    // return a case with all the proper fields
    const newCase = {
      ...caseItem,
      organization: await getCaseOrganization(caseItem),
      owner: await getCaseOwner(caseItem),
      resident_one: formatResident(caseItem),
      formatted_address: formatAddress(caseItem),
      jurisdiction: getFormattedJurisdiction(caseItem),
    };

    return newCase;
  };

  useEffect(() => {
    const getFormattedCases = async () => {
      const formattedCases = await Promise.all(
        cases.map(async (caseItem) => {
          return await getFormattedCase(caseItem);
        })
      );
      setFormattedCases(formattedCases);
    };

    getFormattedCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cases, owners, organizations]);

  const columns = [
    {
      name: "Created At",
    },
    {
      name: "Property Owner",
    },
    {
      name: "Property Management",
    },
    {
      name: "Resident 1",
    },
    {
      name: "Address",
    },
    {
      name: "Jurisdiction",
    },
  ];

  const rows = formattedCases.map((caseItem) => {
    return {
      id: caseItem.id,
      api_endpoint: `/organizations/${organization_id}/cases/${caseItem.id}`,
      endpoint: `/cases/${caseItem.id}/${caseItem.organization_id}`,
      fullRecord: caseItem,
      columns: [
        {
          display: formatDateYearMonthDay(caseItem.created_at),
          value: caseItem.created_at,
        },
        {
          display: caseItem.owner,
          value: caseItem.owner,
        },
        {
          display: caseItem.organization,
          value: caseItem.organization,
        },
        {
          display: caseItem.resident_one,
          value: caseItem.resident_one,
        },
        {
          display: caseItem.formatted_address,
          value: caseItem.formatted_address,
        },
        {
          display: caseItem.jurisdiction,
          value: caseItem.jurisdiction,
        },
      ],
    };
  });

  const [onlyActive, setOnlyActive] = useState(false);

  const sortedRows = [...rows].sort((a, b) => {
    return (
      new Date(b.fullRecord.created_at) - new Date(a.fullRecord.created_at)
    );
  });

  const filteredRows = sortedRows.filter((row) => {
    if (onlyActive) {
      return !!row.fullRecord.active;
    }
    return true;
  });

  if (loading) {
    return <div className="loader mt-7" />;
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Cases"
          subtitle="Case management"
          className=""
        />
        <Link className="btn btn-primary btn-lg" to="/cases/add">
          New Case
        </Link>
      </Row>
      <Row>
        <Col>
          <FormCheckbox
            checked={onlyActive}
            onChange={() => setOnlyActive(!onlyActive)}
            className="text-primary"
          >
            Only Active Cases
          </FormCheckbox>
        </Col>
      </Row>
      <br />

      <Row>
        <Col>
          <TableView
            name="Active Cases"
            columns={columns}
            rows={filteredRows}
            refreshTable={getCases}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Cases;
