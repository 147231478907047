import React from "react";
import {
  formatAddress,
  formatDateYearMonthDay,
  formatResident,
} from "../../utils/formatting";
import { CardHeader } from "shards-react";
import styles from "./card.module.scss";

/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

function PendingOnHoldCases({ pendingOnHoldCases }) {
  const navigate = useNavigate();

  return (
    <div css={css``}>
      <CardHeader className="border-bottom">
        <h6 className="m-0">Pending On Hold Cases</h6>
      </CardHeader>
      <div className="p-3">
        <table className="w-100">
          <thead>
            <tr>
              <th>Date Created</th>
              <th>Management Organization</th>
              <th>Resident</th>
              <th>Address</th>
              <th>Reason for Hold</th>
            </tr>
          </thead>
          <tbody>
            {pendingOnHoldCases.map((caseItem) => {
              return (
                <tr
                  key={caseItem.id}
                  className={styles["card-item"]}
                  onClick={() => {
                    navigate(
                      `/cases/${caseItem.id}/${caseItem.organization_id}`
                    );
                  }}
                >
                  <td>{formatDateYearMonthDay(caseItem.created_at)}</td>
                  <td>{caseItem.organization.name}</td>
                  <td>{formatResident(caseItem)}</td>
                  <td>{formatAddress(caseItem)}</td>
                  <td>{caseItem.on_hold_reason}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        {!pendingOnHoldCases.length && <span>No cases.</span>}
      </div>
    </div>
  );
}

export default PendingOnHoldCases;
